@media only screen and (max-width: 600px) {
  .strip:before {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: calc((100vw - 120rem) / 2 + 47.5 / 100 * 120rem);
    background-color: #f6f6f6;
    z-index: -1;
  }
  .strip:before {
    @apply ltr:right-0 rtl:left-0;
  }
}

.content h3 {
  @apply uppercase;
}

.content strong {
  @apply inline-block pb-sm;
}

.content p:first-of-type {
  @apply inline-block border-t border-[#d0d0d0] pt-sm text-lg text-dark;
}

.content span {
  @apply inline-block pt-sm text-sm;
}

/* .calendarHeader:hover {
    @apply bg-primary;
} */

.calendarSelected:not(:disabled) {
  @apply bg-primary !important;
}

.calendarOutside {
  @apply opacity-0;
}

.calendarMonthPickerControlActive,
.calendarYearPickerControlActive {
  @apply bg-primary hover:bg-primary hover:shadow-lg;
}

.calendarHeaderLevel {
  @apply text-[1.777rem] font-bold uppercase text-dark;
}

.calendarHeaderControl svg {
  @apply h-[1.111rem] w-[1.111rem] text-dark;
}

/* .calendarBase, .calendarBase div, .calendarBase table {
    @apply w-full max-w-full
} */

.callendarCell button {
  @apply mt-xs text-base text-dark;
}

.calendarWeekday {
  @apply text-base font-bold text-dark;
}

.calendarWeekdayCell {
  @apply mb-md border-b-2 border-primary first:border-0 last:border-0;
}

.timePickerWrapper {
  border-radius: 5px;
  background-color: #f6f6f6;
  margin: 0 2em;
  border-bottom: 1px solid #000;
  font-weight: 600;
  color: #ff4848;
  display: flex;
  align-items: center;
  min-width: 7em;
  justify-content: space-between;
}

.counters {
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
}

.formWrapper input {
  @apply h-[2.22rem] w-full rounded-lg border-0 border-b border-b-dark  bg-[#f6f6f6] py-xs px-md text-sm text-black;
}

.formWrapper input {
  outline: 0;
  transition: border-color 0.3s ease-in-out;
}

.formWrapper label {
  @apply mt-sm text-sm text-primary;
}
