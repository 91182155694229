.colWrapper div {
    @apply  h-auto;
}
.colWrapper div:first-of-type {
    @apply  hidden md:block !important;
}
.content div:first-of-type {
    @apply block !important;
}

.content p {
@apply text-sm lg:text-base text-center text-dark md:text-white !important;
}

.content.content {
@apply  h-auto pb-md;
}

.link a {
@apply text-sm lg:text-base
}