.card {
    box-shadow: 3px 14px 65px rgba(42, 42, 42, 0.1);
    border-radius: 5px;
    background-color: rgba(9, 149, 221, 0.01);
    background-color: #ffffff;
    background-image: linear-gradient(to top, rgba(9, 149, 221, 0.85) 0%, rgba(249, 247, 247, 0.85) 1%, rgba(249, 247, 247, 0.85) 100%);
    margin-bottom: 2.777rem;
}

.card {
    @apply border-b-[3px] border-b-[#2fa5e2]
}

.title {
    @apply py-md border-b border-b-[#ccc] border-opacity-[0.35];
}

.content {
    @apply py-md pb-lg
}

.title p {
    @apply text-secondary  text-sm lg:text-lg leading-[1.3]
}

.content p {
    @apply mb-0 text-sm lg:text-base
}

.content p, .title p {
    padding: 0 1.388rem;
}