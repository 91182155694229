.accordionItemTitle {
  @apply w-full max-w-full;
}

.title p {
  @apply text-dark hover:text-secondary ltr:text-left rtl:text-right;
}

.itemOpened .title p {
  @apply text-secondary;
}

.content p {
  @apply text-dark;
}
.content strong {
  @apply text-base text-dark;
}

.accordionControl {
  @apply px-0 hover:bg-transparent;
}
.contentInner {
  @apply px-0;
}
.icon {
  @apply ltr:ml-md ltr:mr-0 rtl:mr-md rtl:ml-0;
}
