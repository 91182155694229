.content ul {
    @apply list-disc list-inside
}
.content li {
    @apply pb-xs
}

.title p {
    @apply hidden md:block
}

.title h2 {
    @apply text-h5 ltr:text-left rtl:text-right
}

.content {
    @apply hidden md:block pb-[6.5rem]
}

.textWrapper {
    @apply absolute py-md bottom-0 ltr:left-0 rtl:right-0 w-full bg-secondary
}

.textWrapper button {
    @apply text-white flex
}