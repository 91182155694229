.product-tabs .productItem {
  @apply flex w-[calc(25%-0.5em)] flex-col items-center rounded-md bg-[rgba(45,45,45,0.9)]  text-[0.85em] font-semibold text-[#999] ltr:font-gilroy rtl:font-dubai;
  backdrop-filter: blur(2px);
}
.product-tabs .productItem > div {
  @apply -mb-2xl -translate-y-1/2;
}
.product-tabs .productItem img {
  @apply relative top-[1em] grayscale;
}
.product-tabs .productItem > span {
  @apply block pb-sm pt-xs;
}
.product-tabs .productItem.active {
  @apply border border-[#535050] text-white;
  box-shadow: 0px 4.19654px 10.45858px rgba(255, 72, 72, 0.4);
}

.product-tabs .productItem.active img {
  @apply grayscale-0;
  box-shadow: 0px 4.19654px 10.45858px rgba(255, 72, 72, 0.4);
}
