.tabControl {
  @apply bg-[#f8f8f8] p-lg transition-all duration-300 ease-in-out;
}

.wrapper {
  overflow: hidden;
}

.wrapper {
  @apply sticky  z-[5] hidden pt-0 shadow-lg lg:block;
}

.tabActive .tabLabel {
  @apply text-secondary;
}

.tabLabel {
  @apply text-base uppercase text-dark;
}

.link a {
  @apply text-lg;
}

.tabsList {
  @apply hidden xl:flex;
}
