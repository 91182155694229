
.title {
    background:url("https://cdn.capex.com/uploads/Stocks_bk_cc94ff1137/Stocks_bk_cc94ff1137.jpg");
}

.title span {
    display: block;
    mix-blend-mode: screen;
    background: white;
    position: relative;
}

.exhangesTitle h4 {
    @apply text-[1.33rem];
}