.content h3 {
    @apply uppercase pb-sm;
}

.content strong {
    @apply inline-block pb-sm font-bold
}

.content p {
    @apply pt-sm inline-block border-t border-[#d0d0d0]
}

.sheetContent p {
    @apply text-dark text-sm lg:text-base
}

.group:hover .sheetContent p {
    @apply text-white;
}

.button button {
    @apply bg-lightBorderGrey text-primary
}

.group:hover .buttonArrow, .group:hover .buttonArrow:after, .group:hover .buttonArrow:before  {
    background: white;
}

.buttonArrow {
    height: 2px;
    position: relative;
    background: currentColor;
    flex-grow: 1;
}

.buttonArrow {
    @apply ltr:left-[-2.25rem] rtl:right-[-2.25rem]
}

.buttonArrow:after{
    content: "";
    bottom: 0;
    height: 2px;
    width: 15px;
    background: currentColor;
    position: absolute;
    display: block;
    transform: rotate(35deg);
}

.buttonArrow:after {
    @apply ltr:right-0 rtl:left-0 ltr:origin-right rtl:origin-left
}

.buttonArrow:before{
    content: "";
    top: 0;
    height: 2px;
    width: 15px;
    background: currentColor;
    position: absolute;
    display: block;
    transform: rotate(-35deg);
}

.buttonArrow:before{
    @apply ltr:right-0 rtl:left-0 ltr:origin-right rtl:origin-left
}

@media only screen and (max-width: 768px) {
  .buttonArrow {
    left: -1.15rem;
}
}