.title p {
    @apply text-dark text-lg
}

.cardShadow > div:first-of-type {
    box-shadow: 0px 10px 14px -1px rgb(0 0 0 / 28%);
    border-radius: 10px;
    overflow: hidden;
}

.content p {
    @apply text-xs text-dark 
}
