.columnList {
    border-right: 1px solid rgba(215, 215, 215, 0.3);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1.3rem 1.3rem 3.5rem;
    font-size: 1.111rem;
    width: 100%;
    margin-bottom: 0px;
}

.columnList p{
    color: #fff;
}

.title h2 {
    color: #fff;
}

.title p {
    color: #fff;
}

.column p {
    color: #fff;
}

.column p:last-child {
    @apply text-xs
}
