.shadow {
  box-shadow: 11px 14px 40px rgba(0, 0, 0, 0.05);
  @apply relative w-[75%] justify-start md:w-auto md:justify-between;
}
.shadow:after {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border-style: dashed;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-width: 2px;
  content: "";
  position: absolute;
  left: 100%;
  bottom: 0;
  @apply border-b-0 border-l-0 border-secondary last:hidden md:hidden;
}
.shadow:before {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border-style: dashed;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-width: 2px;
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  @apply border-b-0 border-l-0 border-secondary last:hidden md:hidden;
  transform: scaleX(-1);
}

.imageWrapper > div {
  @apply mr-[1.5rem] w-full;
}
