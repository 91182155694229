.wideCard:last-child {
    @apply max-w-full
}

.wideCard:last-child > div {
    @apply flex-row
}
.wideCard:last-child > div > div,
.wideCard:last-child > div > div > div,
.wideCard:last-child > div > div > div > span  {
    @apply h-full !important
}

.wideCard:last-child > div > div:last-child {
    @apply flex-row justify-between max-w-[70%]
}

.wideCard:last-child > div > div:last-child > div {
    @apply max-w-[48%]
}

.wideCard:last-child > div > div:last-child > div:last-child {
    @apply mt-0
}