.webinarWrapper {
    @apply flex flex-col lg:flex-row justify-between pb-lg lg:pb-[3.8rem] px-0 relative; 
}

.leftSide {
    @apply w-full lg:w-[33.333%] lg:pr-[3.33rem] py-md lg:border-r lg:border-r-[#cecece] relative;
}

@media (min-width:1024px) {
    .leftSide:before {
    width: 30px;
    height: 30px;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -15px;
    background: #fff;
    }
}

@media (min-width:1024px) {
   .leftSide:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4.5px 0 4.5px 6px;
    border-color: transparent transparent transparent #cecece;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -4px;
    z-index: 1;
    }
}

.rightSide {
    @apply w-full lg:w-[66.66%] lg:pl-[3.33rem] py-md;
}

.focusList {
    border-radius: 5px;
    background-color: #f3f3f3;
    margin-bottom: 1.5rem;
    position: relative;
    overflow: hidden;
}

.focusList p {
    @apply py-sm px-md
}