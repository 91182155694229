.feature-cards{
    @apply md:flex text-center;
}
.feature-cards > div{
    @apply px-md items-center  ;
}
.feature-cards > div:last-child{
    @apply md:mb-xl;
}
.feature-cards > div > div:first-child{
    @apply h-[4em] flex items-center;
}
.feature-cards > div > div:first-child:empty{
    @apply hidden;
}

.feature-cards.no-icons h3{
    @apply mt-0;
}
.feature-cards h3{
   @apply text-[1.3rem] md:text-h4 leading-[1.33em];
}