.sliderThumb {
    @apply h-[1.11rem] w-[1.11rem] border-0 bg-secondary 
}

.sliderBar {
    @apply bg-secondary rtl:right-[calc(0%-8px)];
}

.sliderThumb {
    @apply shadow-lg rtl:translate-x-1/2 rtl:-translate-y-1/2;
}

.sliderTrack {
    @apply h-[6px]
}