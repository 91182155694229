.label {
    @apply text-white text-base ltr:text-left rtl:text-right
}

.control {
    @apply bg-transparent hover:bg-transparent p-sm
}

.item {
    @apply border-0 border-b border-b-[#fff] border-opacity-30
}

.icon {
    @apply ltr:ml-sm rtl:mr-sm rtl:ml-0
}