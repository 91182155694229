.divider{
    @apply flex justify-center items-center after:grow after:h-[1px] after:bg-lightGrey before:grow before:h-[1px] before:bg-lightGrey;
}
.divider span{
    @apply block px-lg;
}

.divider.title-left{
    @apply before:hidden justify-start;
}
.divider.title-left span{
    @apply ltr:pl-0 rtl:pr-0;
}
.divider.title-right{
    @apply after:hidden justify-end;
}
.divider.title-right span{
    @apply ltr:pr-0 rtl:pl-0;
}
.small-divider .divider{
    @apply after:max-w-[8rem] before:max-w-[8rem]
}