.title p {
    @apply md:text-xl text-dark md:max-w-[80%] lg:max-w-[60%] mx-auto
}

.lower_content {
    @apply lg:max-w-[63%]
}

.lower_content p {
    @apply text-center ltr:lg:text-left rtl:lg:text-right
}