

.accordionControl {
    @apply border-none;
}

.accordionItem {
    @apply bg-[#f8f8f8] mb-md border-0 rounded-lg
}

.accordionLabel {
    @apply text-lg text-secondary  ltr:text-left rtl:text-right;
}

.icon{
    @apply ltr:ml-md ltr:mr-0 rtl:mr-md rtl:ml-0;
}
