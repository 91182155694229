.modelList div:first-child div .list ul {
  @apply md:items-end;
}

.modelList > div:first-child div p {
  @apply ltr:md:text-right rtl:md:text-left;
}

.modelList li {
  @apply w-full md:justify-end;
}

.modelList div:last-child div .list ul li {
  @apply md:flex-row-reverse md:justify-end;
}

.modelList div:last-child div .list ul li svg {
  @apply ltr:md:ml-0 ltr:md:mr-[1em] rtl:md:mr-0 rtl:md:ml-[1em];
}

.modelList div:first-child .button {
  @apply mb-xl md:mb-0 md:justify-end;
}
