.accordion-item{
    @apply relative;
}
.accordion-item .accordion-title{
    @apply p-md block w-full  relative cursor-pointer;
}
.accordion-item .accordion-title.with-icon{
    @apply pr-[2.5rem];
}
.accordion-item.center {
    @apply text-center;
}
.accordion-item.center .accordion-title.with-icon{
    @apply pl-[2.5rem];
}
.accordion-item .accordion-content{
    @apply  transition-all duration-200 overflow-hidden opacity-0;
}
.accordion-item.active .accordion-content{
    @apply   opacity-100;
}
.accordion-item.grey{
    @apply  bg-[#f3f3f3]  text-grey rounded-md mb-sm last:mb-0;
}
.accordion-item.border{
    @apply border-0 border-t-borderGrey border-t last:border-b-borderGrey last:border-b
}
.accordion-item.border .accordion-title,
.accordion-item.border .accordion-title p{
    @apply text-secondary;
}
.accordion-item .accordion-icon{
    @apply h-[10px] w-[10px] top-[calc(1.15rem+1.5em/2-5px)] absolute  ltr:right-[1rem] rtl:left-[1rem]
}
.accordion-item.plus .accordion-icon{
    @apply
    after:h-full after:w-[2px] after:bg-current after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:transition-all after:duration-200
    before:h-[2px] before:w-full before:bg-current before:absolute before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 
}

.accordion-item.plus.active .accordion-icon{
    @apply after:h-0
}

.accordion-item.arrow .accordion-icon{
    @apply transition-all duration-200
     after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:transition-all after:duration-200 after:border-l-[0.28em] after:border-l-transparent after:border-r-[0.28em] after:border-r-transparent after:border-t-[0.35em] after:border-t-current after:absolute rotate-0
}
.accordion-item.arrow.active .accordion-icon{
    @apply rotate-180;
}

.accordion-item.shadow{
    @apply shadow-big  rounded-md;
}
.accordion-item.shadow.active{
    @apply shadow-bigger;
}

.accordion-content-inner {
    @apply bg-transparent;
}