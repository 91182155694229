.column-left {
    @apply relative z-10 flex flex-col items-center lg:items-start w-full lg:max-w-[40%] lg:pr-md
}
.blue-box {
    @apply w-full md:w-auto bg-secondary text-white rounded py-[0.27rem] px-md flex justify-end relative mt-[3.333rem] transition-all duration-300 ease-in-out hover:shadow-xl
}
.column-right {
    @apply hidden mt-lg lg:mt-0 md:flex flex-col w-full lg:max-w-[60%] 
}

.richEditor > div > p {
    @apply text-center text-base md:text-2xl md:w-[80%] md:mx-auto lg:w-full lg:text-left
}