.center {
  @apply items-center;
}
.left {
  @apply items-start ltr:text-left rtl:text-right;
}
.right {
  @apply items-start ltr:text-right rtl:text-right;
}
/* .section-title:last-child{
    @apply mb-0
} */
