.thematixWrapper {
  direction: ltr;
  @apply rtl:text-right;
}
.thematixInnerArCard,
.thematixInnerAr {
  direction: rtl;
}
.thematixInnerArCard {
  @apply block;
}
.halfCircle {
  width: 40vh;
  height: 80vh;
  position: absolute;
  right: 30%;
  top: 0;
  border-top-right-radius: 80vh;
  border-bottom-right-radius: 80vh;
  border: 1px solid #d3d5d3;
  border-left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.titleStyles div {
  @apply mx-auto text-center md:max-w-[70%] lg:max-w-full lg:text-left;
}

.titleStyles p {
  @apply hidden lg:block;
}

.halfCircle:before {
  content: "";
  position: absolute;
  left: -1rem;
  top: -1rem;
  height: 150%;
  width: 20vh;
  background: #fcfffb;
}

.disclaimer {
  color: #4f4f4f;
  font-style: italic;
}

@media (min-height: 1000px) {
  .halfCircle {
    width: 18rem;
    height: 36rem;
    border-top-right-radius: 36rem;
    border-bottom-right-radius: 36rem;
  }
  .halfCircle:before {
    width: 10rem;
    left: -1rem;
    top: -1rem;
    height: 60rem;
  }
}
