.linkWrapper {
  position: absolute !important;
}

.title > div {
  @apply mb-[2rem] lg:mb-[5rem];
}

.blueText * {
  @apply text-secondary !important;
}

.greekStyling:lang(el) {
  @apply leading-[1] bottom-[0.5rem]
}