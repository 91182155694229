.terms p {
    color: rgba(255,255,255,.5);
    font-size: 0.888rem;
}

.trading_times h4 {
    @apply text-white font-bold text-lg
}

.trading_times p {
    @apply text-white
}