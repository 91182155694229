.column-left {
    @apply w-full basis-[39%] md:basis-[35%] lg:basis-[39%] grow-0 shrink-0
}
.column-middle {
    @apply w-full lg:w-[36%] basis-[36%] grow-0 shrink-0 px-0 md:px-[1.5rem] lg:px-[4.5rem]
}
.logo-wrapper {
    @apply bg-[#f6f6f6] rounded p-sm lg:p-lg flex justify-center items-center relative max-w-full max-h-[13.125rem]
}
.column-right {
    @apply w-full md:w-[30%] lg:w-[24%] block md:flex basis-[24%] md:basis-[30%] lg:basis-[24%] grow-0 shrink-0 items-center text-center
}
.list li{
    @apply items-center text-base pb-xs flex text-secondary
}
.list li span{
    @apply text-grey mx-[0.25rem]
}
.list li div{
    @apply flex justify-between grow md:block md:grow-0
}
.icon {
    @apply ltr:mr-sm rtl:ml-sm text-grey text-sm inline-block align-middle h-[1.35rem]
}
.link-wrapper a {
    @apply absolute top-0 left-0 w-full h-full z-10;
}