.titleStyles div {
  @apply mx-auto text-center max-w-[90%] md:max-w-[70%] lg:max-w-full ltr:lg:text-left rtl:lg:text-right;
}

.titleStyles h2 {
  @apply text-[1.777rem] md:text-h2
}

.titleStyles p {
  @apply hidden lg:block;
}

.gradient_box {
  background-image: linear-gradient(180deg, hsl(213deg 38% 94%) 0%, hsl(213deg 38% 97%) 77%, hsl(0deg 0% 100%) 100%);
}
.disclaimer {
  color: #4f4f4f;
  font-style: italic;
}
.chart_lines_box {
  box-shadow: 0 21px 31px rgba(30, 73, 97, 0.1);
  border-radius: 10px;
}

.customShadow {
  box-shadow: 0 -10px 20px -5px rgba(0, 0, 0, 0.08);
}