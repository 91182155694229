.innerCard {
  @apply grow lg:border-r lg:px-xl;
}
.outerCard:last-child .innerCard {
  @apply border-none;
}
.innerCard {
  border-color: rgba(214, 214, 214, 0.6);
}
.content {
  @apply flex flex-col;
}
