.buttons button {
    @apply w-full md:w-auto first:mb-md first:md:mb-0 p-sm lg:py-[0.833em] lg:px-[3.333em]
}

.title {
    background:url("https://cdn.capex.com/uploads/Stocks_bk_cc94ff1137/Stocks_bk_cc94ff1137.jpg");
}

.title span {
    display: block;
    mix-blend-mode: screen;
    background: white;
    position: relative;
}
