.title h1 {
  @apply relative  text-center text-h2 uppercase text-dark ltr:font-gilroy ltr:font-black rtl:font-dubai rtl:font-bold md:text-left md:text-[3rem] lg:top-0;
}

.subtitle p {
  @apply mb-0 text-dark ltr:font-gilroy rtl:font-dubai;
}

.title div {
  @apply mb-md text-center md:text-left;
}

.button {
  @apply mb-lg;
}
.button button {
  @apply ltr:font-gilroy rtl:font-dubai;
}
