.selectLabel {
  @apply text-xs text-secondary;
}

.selectInput {
  @apply cursor-pointer border-0 border-transparent pl-0 text-lg text-secondary focus:border-transparent focus:ring-0;
}

.selectInput input:selection {
  @apply bg-primary text-white;
}
.title {
  @apply text-white;
}

.title h1 {
  @apply -mb-lg lg:mb-0;
}
