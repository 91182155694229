.cols p, .cols li {
    @apply text-white
}

.cols li {
    @apply pb-md mx-md list-disc marker:text-secondary marker:text-xl
}

.title {
    @apply text-white
}

.content p  {
    @apply text-white mb-0
}