.text_slider p {
  @apply relative top-[0.05em] mb-0 text-[2.66rem] font-bold leading-[1] ltr:font-gilroy rtl:font-dubai lg:-top-[0.05em];
}

.customBackground {
  background: linear-gradient(90deg, #fafafa 4.26%, rgba(241, 241, 241, 0.16) 100%);
}
.disclaimer,
.disclaimer > p,
.disclaimer > a,
.disclaimer > span {
  color: #4f4f4f;
  font-style: italic;
}
.disclaimer p {
  @apply text-xs italic lg:text-base;

  a {
    @apply text-[#4f4f4f] hover:text-secondaryHover;
  }
}
.turquoise p {
  @apply text-[#60ABBB];
}
.textBlue {
  color: #0995dd;
  margin: 0;
}

.heroBottom {
  @apply mt-lg flex flex-col  lg:mt-[5em] lg:flex-col;
}

.heroCard {
  flex-grow: 1;
  flex-basis: 0;
  border: 1px solid #dbd9d9;
  @apply -mt-[1px] p-md text-center ltr:-mr-[1px] rtl:-ml-[1px] lg:p-[2rem] ltr:lg:text-left rtl:lg:text-right;
}

.discover_title {
  padding: 2em 0;
  margin: 0;
  text-align: center;
  border-top: 1px solid #363636;
}
.discover_title {
  @apply m-0 text-center font-bold leading-[1.2] ltr:font-gilroy rtl:font-dubai;
}

.green p {
  @apply text-[#22A216];
}

.blue p {
  @apply text-[#2744B8];
}

.brown p {
  @apply text-[#A53131];
}

@media (min-width: 1024px) {
  .shadowContainer:after {
    content: "";
    width: 20%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    z-index: 2;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(252, 255, 251, 1) 70%);
    @apply ltr:right-0 rtl:left-0 rtl:rotate-180;
  }
}

.link a {
  @apply absolute top-0 left-0 z-10 h-full w-full;
}

.roundedImage span {
  @apply overflow-hidden rounded-full;
}

.investHeroTitle {
  h1 {
    @apply text-[2rem] font-black  rtl:leading-[1.4] lg:text-[4rem] xl:text-[3.8rem] 2xl:text-[4.5rem];

    & > small {
      @apply block pb-lg text-[1em]  rtl:leading-[1.4] lg:pb-0 lg:text-[0.888em];

      strong {
        @apply -mb-[0.4em] mt-[0.2em] text-[2.8em] leading-[0.8] lg:text-[1.5em] lg:leading-[0.6];
      }
    }

    strong {
      @apply block text-[3.5em] lg:inline lg:text-[1.8em];
    }
  }
}

@media (max-width: 1023px) {
  .investHeroTitle strong {
    line-height: 1;
  }
}
