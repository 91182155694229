.mt5-section,
.mt5-section p {
  @apply text-white;
}

.mt5-header h2 {
  @apply pt-sm text-[2.222rem] leading-[1] ltr:font-gilroy ltr:font-black rtl:font-dubai  rtl:font-bold 2xl:text-[2.777rem] 3xl:text-[3.333rem];
}
#mt5-section {
  @apply z-10;
}
