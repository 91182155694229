.content p {
    @apply text-dark text-lg;
}
.contentExtra p {
    @apply text-dark text-lg mb-0 last:text-secondary;
}

.title {
    @apply before:h-[1px] before:content-[''] before:block before:bg-[#e3e3e3] before:grow before:mt-[0.972rem] after:h-[1px] after:content-[''] after:block after:bg-[#e3e3e3] after:grow after:mt-[0.972rem] 
}

.not-featured:nth-child(3n+1) .title:before,
.not-featured:nth-child(3n+3) .title:after{
    @apply hidden;
}