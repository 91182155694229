.accordionItem {
  @apply  relative  border-0 transition-all duration-300 ease-in-out ltr:-ml-md rtl:-mr-md ltr:pl-[12rem] rtl:pr-[12rem] ltr:pr-[3.333rem] rtl:pl-[3.333rem] first:mt-lg last:mb-lg 
  ltr:mr-[2.667rem] rtl:ml-[2.667rem];
}

.accordionControl {
  @apply hover:bg-transparent py-xs px-0 ltr:text-left rtl:text-right
}

.contentInner {
  @apply px-0
}

.accordionIcon {
  @apply hidden
}

.accordionItemOpened {
  @apply pointer-events-none
}

.skewed {
  transform: perspective(300px) rotateX(50deg);   
  -webkit-transform: perspective(300px) rotateX(50deg);    
}


.customAnimation {
	-webkit-animation: fade-in 0.5s ease-in-out both;
  animation: fade-in 0.5s ease-in-out both;
}

@-webkit-keyframes fade-in {
   0% {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
   -webkit-transform: scale(0.75);
           transform: scale(0.75);
   opacity: 0;
 }
 100% {
   -webkit-transform: scale(1);
           transform: scale(1);
   opacity: 1;
 }
}