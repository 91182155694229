.tabsList {
  @apply flex-col items-start;
}

.tabControl {
  @apply relative w-full px-lg py-[1.8rem] transition-all duration-300 ease-in-out;
}
.tabControl {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.wrapper {
  @apply w-1/2 ltr:pr-[2rem] rtl:pl-[2rem] ltr:lg:pr-[11.111rem] rtl:lg:pl-[11.111rem];
}

.tabActive {
  @apply text-secondary;
}

.tabLabel p {
  @apply text-base text-white ltr:text-left rtl:text-right;
}

.tabActive p {
  @apply text-secondary;
}

.tabActive:before {
  content: "";
  width: 100%;
  height: calc(100% - 7px);
  bottom: 4px;
  position: absolute;
  border-radius: 10px;
  background-color: #fff;
  opacity: 0.1;
  left: 0;
}

.tabsRoot {
  @apply flex items-center justify-between;
}

.tabsBody {
  @apply w-1/2;
}

.mobileButton button {
  @apply px-[1.5rem];
}

.itemOpened .accordionItemTitle p {
  @apply text-secondary;
}
.itemOpened .content p {
  @apply text-white;
}

.accordionItemTitle p {
  @apply text-white;
}

.itemOpened {
  @apply relative;
}

.itemOpened:before {
  content: "";
  width: 100%;
  height: calc(100% - 7px);
  bottom: 4px;
  position: absolute;
  border-radius: 10px;
  background-color: #fff;
  opacity: 0.1;
  left: 0;
  z-index: -1;
}

.content span {
  @apply text-lg text-white ltr:font-gilroy rtl:font-dubai lg:text-[1.5rem];
}

.tabInner {
  @apply justify-start;
}

.accordionControl:hover {
  background-color: transparent;
}
