.content {
  @apply w-[65%] md:max-w-[70%];
}

.content h1 {
  @apply hidden pb-sm text-[3.55rem] leading-[1] ltr:font-gilroy ltr:font-bold rtl:font-dubai rtl:font-normal md:block 2xl:text-[5.60rem];
}

.content h3 {
  @apply text-3xl md:text-[2.22rem];
}

.content h1 > strong {
  @apply text-primary;
}

.terms p > a {
  @apply block pt-md text-center text-sm text-white opacity-60 hover:opacity-70 ltr:lg:text-left rtl:lg:text-right;
}

.partners-hero > div {
  @apply rtl:items-end;
}

.partners-hero .content {
  @apply max-w-[72%] rtl:max-w-[60%] md:max-w-[60%] md:rtl:max-w-[55.5%];
}

.partners-hero .content h1,
.partners-hero .content h2 {
  @apply block text-[2rem] uppercase ltr:font-gilroy ltr:font-black rtl:font-dubai rtl:text-[1.6rem] rtl:font-bold md:text-[2.7rem]   md:rtl:text-[2.7em];
}

.partners-hero .content h2 strong {
  @apply text-primary md:block;
}
.partners-hero .content strong {
  @apply hidden md:block;
}

.partners-hero .button {
  @apply md:mb-lg md:rtl:w-[55.5%] lg:mb-0;
}

.partners-hero button {
  @apply hidden md:block;
}

.refer-and-earn-hero h1 strong {
  @apply text-white;
}

.refer-and-earn-hero .content {
  @apply w-[50%] md:w-[60%];
}

.refer-and-earn-hero h1 {
  @apply block max-w-[55%] text-4xl md:max-w-full md:text-[3.55rem];
}

.refer-and-earn-hero h3 {
  @apply text-base lg:text-h3;
}

.refer-and-earn-hero .content {
  @apply mb-[8rem] md:mb-0;
}

.refer-and-earn-hero .button {
  @apply flex justify-center md:block;
}
