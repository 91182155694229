.columnList {
    border-right: 1px solid rgba(215, 215, 215, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.3rem 1.3rem 2rem;
    font-size: 1.111rem;
    width: 100%;
    margin-bottom: 0px;
}

.columnList {
  @apply last:border-0
}

.columnList {
  @apply text-dark;
}

.columnList p {
    @apply text-grey text-sm 2xl:text-base;
}

.columnList a {
  @apply cursor-pointer
}

.columnListDark p{
  @apply text-base lg:text-lg 2xl:text-base;
}

.columnDark h2 {
  @apply text-white font-bold text-3xl 2xl:text-h2
}

.column p {
  @apply text-grey text-sm md:text-base 2xl:text-base
}

.column p:last-child {
    font-size: 14px;
}

.link {
  @apply  mt-md;
}

.link p {
  @apply text-secondary cursor-pointer
}

.link p::before {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  border-top: 0.28em solid transparent;
  border-bottom: 0.28em solid transparent;
  margin-bottom: 0.25rem;
}

.link p::before {
  @apply ltr:border-l-[0.35em] ltr:mr-[1em] rtl:ml-[1em] rtl:border-r-[0.35em] ltr:border-l-[#0995dd] rtl:border-r-[#0995dd]
}

.hasImage {
  @apply hidden !important;
}

.column ul {
  @apply my-lg hidden md:block;
}

.column ul>li {
  @apply relative ltr:pl-md rtl:pr-md mb-xs text-grey text-sm 2xl:text-base;
}

.column ul>li:before {
    content: "";
    position: absolute;
    display: block;
    top: 0.35rem;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background: #0995dd;
}

.column ul>li:before {
  @apply ltr:left-0 rtl:right-0
}

.disclaimer p {
  @apply text-grey text-sm 2xl:text-base;
}

.activeText.activeText p > span {
  @apply text-[#858585] !important
}


.smaller-image > div > div:last-child {
  @apply max-w-[70%] lg:max-w-[40%] 2xl:max-w-[50%]
}

.smaller-image {
  @apply pt-md md:pt-0
}