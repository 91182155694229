.strip:before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: calc((100vw - 120rem)/2 + 40.5/100*120rem);
        background-color: #f6f6f6;  
        z-index: -1;
    }

    .strip:before {
    @apply ltr:right-0 rtl:left-0
}

@media only screen and (max-width: 600px) {
    .strip:before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: calc((100vw - 120rem)/2 + 47.5/100*120rem);
        background-color: #f6f6f6;  
        z-index: -1;
    }
}

.content h3 {
    @apply uppercase;
}

.marketingContent h3 {
    @apply uppercase;
}

.content strong {
    @apply inline-block pb-sm
}

.content p {
    @apply pt-sm inline-block border-t border-[#d0d0d0]
}

.programContent p {
    @apply text-sm;
}

.lpContent h3 {
    @apply text-base lg:text-[1.333rem] pb-md lg:pb-lg
}
.lpContent p {
    @apply text-sm lg:text-base
}

.mobileButton button {
    @apply px-[1.5rem]
}