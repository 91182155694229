.columns div > div {
    @apply ltr:first:text-right rtl:first:text-left
}

.infoLeft {
    @apply ltr:lg:border-r rtl:lg:border-l lg:border-[rgba(204,204,204,.5)] text-center ltr:lg:text-right rtl:lg:text-left relative items-center ltr:lg:items-end rtl:lg:items-start lg:before:content-[""] lg:before:absolute lg:before:bg-primary lg:before:h-[12px] lg:before:w-[12px] lg:before:rounded-full lg:before:top-1/2 ltr:lg:before:right-0 rtl:lg:before:left-0 lg:before:transform ltr:lg:before:translate-x-1/2 rtl:lg:before:-translate-x-1/2 lg:before:translate-y-1/2 
}

.infoCol {
    @apply flex-grow basis-0 py-md lg:px-[5.555rem] flex flex-col justify-center
}

.content p {
    @apply rtl:text-right
}