.card-inner {
    @apply rounded py-lg px-xs bg-[#f6f6f6] flex flex-col justify-between h-full mx-xs relative
} 

.card-top {
    @apply flex justify-between
}

.left-side {
    @apply w-[38%] bg-white rounded p-md mx-xs flex justify-center items-center relative
}

.left-side a {
    @apply absolute top-0 left-0 h-full w-full z-10 cursor-pointer
}

.right-side {
    @apply w-[62%] mx-xs
}

.img-wrapper img {
    @apply max-h-[4.21875rem] max-w-full w-auto inline-block
}

.card-bottom {
    @apply mx-xs pt-xl flex  items-center flex-col justify-center lg:flex-row lg:justify-between
}

.card-bottom a {
    @apply rtl:text-sm
}
