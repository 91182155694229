.disclaimer p {
    @apply text-xs text-dark;
}

.iframeContainer {
    @apply overflow-visible p-0 !important;
}

.iframeContainer iframe {
    @apply w-full h-screen
}