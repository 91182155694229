.mantine-NativeSelect-wrapper select {
  @apply h-full cursor-pointer border-secondary text-base text-secondary focus:ring-0;
}

.mantine-NativeSelect-wrapper option {
  @apply p-0;
}

.selectInputOpened {
  @apply bg-[#1e90ff] py-lg text-white;
}

.selectInputClosed {
  @apply bg-white py-lg text-secondary;
}

.wrapper {
  @apply overflow-hidden rounded-md border border-secondary;
}

.dropdown {
  @apply border border-secondary bg-white p-0;
}

.itemSelect {
  @apply rounded-none bg-transparent hover:bg-[#1e90ff] hover:text-white;
}
.wrapper input {
  @apply border-none;
}

.embedded iframe {
  @apply h-[100vh] !important;
}
