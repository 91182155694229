.selectLabel {
  @apply text-xs text-secondary;
}

.selectInput {
  @apply cursor-pointer border-0 border-transparent pl-0 text-lg text-secondary focus:border-transparent focus:ring-0;
}

.selectInput input:selection {
  @apply bg-primary text-white;
}
.trading-conditions h4 p {
  @apply text-inherit;
}
.table-row > div {
  @apply grow basis-0 px-md py-sm text-sm lg:px-xs lg:py-0;
}
.mobile-table-row > div {
  @apply grid grid-cols-2;
}
.mobile-table-row > div > div {
  @apply px-md py-xs;
}
.mobile-table-row > div > div:first-child {
  @apply bg-[rgba(127,127,127,.2)];
}

.mobile-table-row > div:first-child > div {
  @apply pt-lg;
}
.mobile-table-row > div:last-child > div {
  @apply pb-lg;
}

.itemSelect,
.selectRoot,
.selectDropdown,
.selectInput,
.selectWrapper {
  @apply rtl:text-right !important;
}

.selectRightSection {
  @apply rtl:left-0 rtl:right-auto;
}
.selectInput {
  @apply rtl:pr-0 rtl:pl-[0.75rem];
}
.selectInput {
  @apply relative z-10 bg-transparent;
}
