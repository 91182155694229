.tabControl {
    @apply bg-[#e5e5e5] hover:bg-[#f8f8f8] transition-all duration-300 ease-in-out p-[1.8rem] md:p-[2.4rem];
}

.wrapper {
    border-radius: 6px 6px 0 0;
    overflow:hidden;
}

.tabActive {
    @apply bg-[#f8f8f8]
}

.tabLabel {
    @apply text-secondary text-lg md:text-[1.4rem]
}

.activeText span, .activeText p, .activeText a  {
    @apply text-secondary text-2xl
}

.notActiveText span, .notActiveText p, .notActiveText a {
    @apply text-dark text-2xl
}