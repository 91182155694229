.title div {
    @apply mb-[1rem] text-center ltr:md:text-left rtl:md:text-right;
}

.title h2 {
    @apply text-center ltr:md:text-left rtl:md:text-right;
}

.tabControl {
    @apply bg-[#e5e5e5] hover:bg-[#f8f8f8] transition-all duration-300 ease-in-out p-[1.8rem] md:p-[2.4rem];
}

.wrapper {
    border-radius: 6px 6px 0 0;
    overflow:hidden;
}

.tabActive {
    @apply bg-[#f8f8f8]
}

.tabLabel {
    @apply text-secondary text-lg md:text-[1.4rem]
}

.body {
    @apply bg-primary transition-all duration-300 ease-in-out
}