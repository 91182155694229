.article-card.primary .article-card-image img{
    @apply scale-100 transition-all duration-500 ease-in-out h-full rounded-md;
}
.article-card.primary:hover .article-card-image img{
    @apply scale-110;
}
.article-card:hover a{
    @apply text-secondaryHover;
}

.article-card p,
.article-card h5{
    @apply transition-all duration-500 ease-in-out;
}
.article-card:hover p,
.article-card:hover h5{
    @apply text-secondaryHover;
}
.article-card-image span,
.article-card-image img{
   @apply align-middle inline-block
}

.descriptionText p {
    @apply text-sm
}
.descriptionText:empty{
    @apply hidden;
}
.article-card-image > span{
    @apply h-full !important;
}

.linkWrapper a {
   @apply absolute top-0 right-0 z-10 h-full w-full
}