.trustpilot_rating_text p {
    @apply text-black tracking-wider
}

.customShadow {
    box-shadow: 0px 8.45931px 32.6288px -6.04237px rgba(40, 1, 1, 0.12);
}

.customShadowReviewCardExtended {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
    border-radius: 20px 10px 10px 10px;
}

.mobileReviewText {
    @apply text-sm pb-lg
}

@media (min-width: 1025px) {
    .shadowContainer:before {
    content: "";
    width: 5%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: inset 35px 0 30px 10px rgb(255 255 255)
}

.shadowContainer:after {
    content: "";
    width: 5%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    z-index: 2;
    box-shadow: inset -35px 0 30px 10px rgb(255 255 255)
}
}