/* mobile stylings */

.AccordionRoot {
    @apply relative
}

.AccordionControl {
    @apply bg-transparent border-0 p-0 px-md hover:bg-transparent
}

.AccordionControl:hover p {
    @apply hover:font-black
}

.AccordionItem:first-child .AccordionControl {
    @apply mt-0
}

.AccordionItem {
    @apply border-0 bg-[#F8F6F6] rounded-lg mb-md py-md overflow-hidden relative
}

.AccordionLabel {
    @apply  text-dark
}

.AccordionLabel p {
    @apply text-lg
}

.contentInner {
    @apply px-0 pt-md pb-md
}

.AccordionContent {
    @apply px-md text-sm relative z-10
}

.borderAccordionIcon {
    @apply text-dark text-lg -ml-[7px]
}

.blue_box_text p {
    @apply text-[#111114] lg:text-white text-center text-h3 leading-[1.25] lg:ltr:text-left lg:rtl:text-right
}