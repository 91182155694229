.input {
    @apply placeholder:opacity-30 border-0 focus:ring-0 focus:border-0 focus:outline-0 focus:shadow-none text-[#4a4a4a] h-[2rem] w-full py-md pl-lg pr-md text-base bg-transparent;
}

.categoryList {
    @apply relative flex-col md:flex-row md:flex py-0 items-center
}

.categoryList {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.pump {
    animation: 2s pulse-green;
    box-shadow: 0 0 0 0 rgb(88 201 20 / 0%);
    background-color: rgba(88,201,20,0);
}
.dump {
    animation: 2s pulse-red;
    background-color: rgba(255,72,72,0);
    box-shadow: 0 0 0 0 rgb(255 72 72 / 0%);
}

@keyframes pulse-green {
    0% {
        box-shadow: 0 0 0 0 rgba(88, 201, 20, 0.3);
        background-color:rgba(88, 201, 20, 0.3);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(88, 201, 20, 0);
        background-color:rgba(88, 201, 20, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(88, 201, 20, 0);
        background-color:rgba(88, 201, 20, 0);
    }
}

@keyframes pulse-red {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 72, 72, 0.3);
        background-color: rgba(255, 72, 72, 0.3);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 72, 72, 0);
        background-color: rgba(255, 72, 72, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 72, 72, 0);
        background-color: rgba(255, 72, 72, 0);
    }
}