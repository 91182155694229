.title h1,
.title h2 {
  @apply text-white;
}

.title p {
  @apply text-white ltr:font-gilroy rtl:font-dubai;
}

.content p {
  @apply text-secondary;
}

.title {
  @apply lg:max-w-[40rem];
}
