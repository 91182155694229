.subtitle p {
  @apply text-[1.888rem] leading-[140%] text-[#f2f2f2] ltr:font-gilroy rtl:font-dubai 2xl:text-[2.4rem]  2xl:leading-[120%] 3xl:text-[2.888rem] 3xl:leading-[140%];
}
@media (max-height: 700px) and (min-width: 1024px) {
  .subtitle p {
    @apply text-[1.4rem];
  }
  .videoSize {
    @apply relative right-[10%] max-w-[35%];
  }
  .title,
  .subtitle,
  .disclaimer,
  .content {
    @apply mb-[1.6rem];
  }
  .heroButton {
    @apply mt-0;
  }
  .blurPreview {
    @apply top-[7%];
  }
  .previewWrapper {
    @apply -mb-[2rem];
  }
}
.content p {
  @apply text-[1.111rem] leading-[140%] text-[#d9d9d9];
}
.disclaimer p,
.disclaimer a {
  @apply text-sm italic leading-[140%] text-[#9F9F9F];
}
.disclaimer a {
  @apply hover:text-secondaryHover;
}
.mobileSubtitle p {
  @apply text-[1.8rem] leading-[1.25] text-white ltr:font-gilroy  rtl:font-dubai;
}

@media (max-height: 700px) and (max-width: 767px) {
  .mobileSubtitle p {
    @apply px-[2rem] text-[1.4rem];
  }
  .mobileTitle h3 {
    @apply px-[2rem];
  }
}

.scroll_icon:before {
  content: "";
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 1.5px;
}

.activeVideoMobile video {
  width: 100vw !important;
  height: calc(100vh + 2rem) !important;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.mouseAnimate {
  animation-duration: 2s;
}

.hoverAnimationPreviewCards:hover > div:first-child {
  @apply -rotate-[20deg] scale-[1.4] border-none;
}

.hoverAnimationPreviewCards:hover > div:first-child > div {
  @apply opacity-[0.8];
}

.hoverAnimationPreviewCards:hover > div:last-child {
  @apply block scale-[1.25] border-[2px];
}

.hoverAnimationPreviewCards:hover > div:last-child svg {
  @apply block fill-[#c8c8c8];
}

.hoverAnimationPreviewCards svg {
  stroke-width: 1.5;
}
.hoverAnimationPreviewCards:hover svg {
  stroke-width: 2.5;
}
.animationDelay {
  animation-delay: 0.5s;
}
.radiusWrapper {
  isolation: isolate;
}
.videoBg video {
  object-fit: cover;
  filter: blur(50px);
  opacity: 0.5;
}
/*
.previewCardWrapper:after {
  position: absolute;
  content: "";
  bottom: -0.2rem;
  left: 0;
  right: 0;
  z-index: -1;
  height: 2rem;
  width: 100%;
  transform: scale(0.9) translateZ(0);
  filter: blur(15px);
  background: linear-gradient(to left, #ff5770, #e4428d, #c42da8, #9e16c3, #6501de, #9e16c3, #c42da8, #e4428d, #ff5770);
  background-size: 200% 200%;
  animation: animateGlow 1.25s linear infinite;
  opacity: 0.5;
}
@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
*/
