.wrapper p {
  @apply text-white;
}
.wrapper h2 {
  @apply text-[3rem] font-bold 2xl:text-[3.666rem];
}
.wrapper h3 {
  @apply mb-md font-bold 2xl:text-h2;
}

.card:nth-child(4n + 2) > div {
  @apply border-[#5A6787];
}
.card:nth-child(4n + 3) > div {
  @apply border-[#4D4162];
}
.card:nth-child(4n + 4) > div {
  @apply border-[#405754];
}
.card h3:lang(it) {
  @apply text-h4;
}

.dotsWrapper {
  background-image: radial-gradient(#727372 10%, transparent 10%);
  background-position: 0 0, 50px 50px;
  background-size: 20px 20px;
  @apply absolute top-1/2 h-[70%] w-[40vw] -translate-y-1/2 opacity-30 ltr:right-0  rtl:left-0;
  border-radius: 50px;
  overflow: hidden;
}
.backgroundImg {
  -webkit-animation: slide-out-fwd-tr 8s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite alternate;
  animation: slide-out-fwd-tr 8s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite alternate;
}

@keyframes slide-out-fwd-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0) scale(1, 1);
    transform: translateZ(0) translateY(0) translateX(0) scale(1, 1);
    opacity: 0.1;
    filter: grayscale(0%);
  }
  50% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0) scale(1, 1);
    transform: translateZ(0) translateY(0) translateX(0) scale(1, 1);
    opacity: 0.1;
    filter: grayscale(0%);
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(200px) scale(1.5, 1.5);
    transform: translateZ(600px) translateY(-300px) translateX(200px) scale(1.5, 1.5);
    opacity: 0;
    filter: grayscale(100%);
  }
}
