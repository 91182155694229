.grayItemOpened {
  @apply text-secondary;
}

.grayAccordionLabel p {
  @apply text-lg font-[400] text-dark transition-all duration-300 ease-in-out hover:text-secondary;
}

.grayItemOpened .accordionLabel p {
  @apply text-secondary;
}

.grayTabInner {
  @apply justify-start;
}

.grayAccordionControl:hover {
  background-color: transparent;
}

.grayAccordionItem {
  background-color: #f6f6f6;
  border-radius: 10px;
  border: 0;
  margin-bottom: 1.111rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.borderAccordionControl:hover {
  background-color: transparent;
}

.borderItemOpened .borderAccordionLabel p {
  @apply text-secondary hover:text-secondary;
}
.borderAccordionLabel p {
  @apply hover:text-secondary;
}

.shadowAccordionControl:hover {
  background-color: transparent;
}

.shadowItemOpened .borderAccordionLabel p {
  @apply text-secondary hover:text-secondary;
}

.shadowAccordionItem {
  @apply rounded-md border-none  shadow-big;
}

.shadowItemOpened {
  @apply shadow-bigger;
}

.borderAccordionLabel {
  @apply rtl:text-right;
}

.borderAccordionIcon {
  @apply rtl:ml-0 rtl:mr-[20px];
}
.contentInner {
  @apply px-0;
}

.grayAccordionControl, .shadowAccordionControl, .borderAccordionControl {
  @apply px-0
}