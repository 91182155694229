.video video {
    -webkit-mask-image: -webkit-radial-gradient(white,#000);
    -webkit-backface-visibility: hidden;
}

.content {
    @apply text-center ltr:lg:text-left rtl:lg:text-right  lg:text-lg
}

.contentLeftMobile span > h3 {
    @apply mb-md
}

.content span {
    @apply text-white;
}

.contentLeftMobile p {
    @apply hidden lg:block
}

.content p, .content h4 {
    @apply text-[#b3b3b3]

}

.accordionControl {
    @apply border-none hover:bg-[#161212]
}


.accordionControl:hover {
    opacity: 1;
    transition: .3s ease-in-out;
    box-shadow: 0 0 40px 20px rgb(0 0 0 / 21%);
}

.itemOpened .accordionControl:hover {
    opacity: 1;
    transition: .3s ease-in-out;
    box-shadow: none;
}

.accordionItem {
    @apply border-0 bg-[#161212] opacity-[0.9] rounded-sm hover:bg-[#161212] text-[#737373] my-md first:mt-0 last:mb-0
}