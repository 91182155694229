.subtitle.subtitle p {
    @apply text-base text-grey text-center
}

.secondaryTitle h4{
    @apply text-grey font-opensans text-lg text-center md:max-w-[80%] md:mx-auto
}

.boxText.boxText p {
    @apply text-grey md:text-xl mb-0 text-center md:text-left
}

.button button {
    @apply w-full
}