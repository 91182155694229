.card {
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    border-radius: 5px;
    background-color: #f3f3f3;
    padding: 1.5rem 1.6rem;
    position: relative;
    transition: box-shadow .3s ease-in-out;
    box-shadow: none;
    cursor: pointer;
    padding-bottom: 0.3rem;
}

.card:hover {
    box-shadow: inset 0 0 8px 0 rgb(0 0 0 / 15%);
}

.accordion > div > div > div  {
    @apply py-[1rem] px-0 text-secondary !important;
}

.accordion > div > div > div > p  {
    @apply  text-secondary !important;
}

.accordion > div > div > div div {
    @apply top-[25%] !important;
}


.accordion > div > div > div {
    @apply  pt-0 !important;
}

.accordion > div > div > div > div {
    @apply  px-0 !important;
}

.accordion > div > div > div > div > ul{
    @apply  px-0 !important;
}