.table_header p {
    @apply font-bold text-xs lg:text-base text-[#7a7a7a] text-center ltr:lg:text-left rtl:lg:text-right
}
.content p {
    @apply text-xs lg:text-base text-[#7a7a7a] text-center ltr:lg:text-left rtl:lg:text-right
}

.disclaimer p, .disclaimer a {
    @apply text-[#737373] text-sm lg:text-base
}

.disclaimer i {
    @apply opacity-60
}

