.title h1 {
  @apply font-bold ltr:font-gilroy rtl:font-dubai lg:text-[2.555rem];
}

.subtitle p {
  @apply text-lg text-dark;
}

.content p {
  @apply text-dark;
}

.content strong {
  @apply text-secondary lg:text-primary;
}

.subtitleMobile p {
  @apply text-lg leading-[1.5] text-dark;
}

.disclaimer a {
  @apply text-sm text-black opacity-50 hover:text-secondaryHover hover:opacity-100;
}
