.left_column {
    @apply w-full lg:w-1/2 p-md py-xl lg:p-xl lg:pl-0;
}

.right_column {
    @apply w-full lg:w-1/2 p-md py-xl lg:p-xl bg-secondary
}

.right_column p {
    @apply text-white text-center ltr:md:text-left rtl:md:text-right mb-lg last:mb-0
}

.left_column h2 { 
    @apply text-center ltr:md:text-left rtl:md:text-right
}

.left_column p {
    @apply mb-lg text-center ltr:md:text-left rtl:md:text-right last:mb-0
}