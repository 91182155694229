.input {
  @apply h-[2rem] w-full border-0 bg-transparent py-md pl-lg pr-md text-base text-[#4a4a4a] placeholder:opacity-30 focus:border-0 focus:shadow-none focus:outline-0 focus:ring-0;
}

.categoryList {
  @apply relative flex-col items-center py-0 lg:flex lg:flex-row;
}

.categoryList {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.dump {
  @apply bg-primary/[.1];
  @apply border-primary/[.3] !important;
}
.pump {
  @apply bg-greenCandle/[.1];
  @apply border-greenCandle/[.3] !important;
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(88, 201, 20, 0.3);
    background-color: rgba(88, 201, 20, 0.3);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(88, 201, 20, 0);
    background-color: rgba(88, 201, 20, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(88, 201, 20, 0);
    background-color: rgba(88, 201, 20, 0);
  }
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 72, 72, 0.3);
    background-color: rgba(255, 72, 72, 0.3);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 72, 72, 0);
    background-color: rgba(255, 72, 72, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 72, 72, 0);
    background-color: rgba(255, 72, 72, 0);
  }
}

.HomeInstrumentRow {
  @apply flex;
}
.HomeInstrumentRow li {
  @apply items-center px-xs py-sm lg:px-md;
  flex-grow: 1;
  flex-basis: 0;
}

.HomeInstrumentRow li > span {
  @apply block rounded-md border border-transparent px-xs py-[0.1em] lg:px-sm lg:py-[0.3em];
}
.searchWrapper input {
  @apply m-0 h-[3em] bg-transparent;
}
.searchWrapper > div > div > div > div {
  @apply bg-transparent;
}

.tabControl {
  @apply py-[2.5rem] text-base text-grey lg:tracking-wider;
}
.tabActive.tabActive {
  @apply border-secondary text-secondary;
}
.tabControl img {
  filter: invert(44%) sepia(14%) saturate(0%) hue-rotate(141deg) brightness(97%) contrast(80%);
}
.tabActive img {
  filter: invert(52%) sepia(99%) saturate(2934%) hue-rotate(171deg) brightness(91%) contrast(93%);
}
.tabLabel {
  @apply hidden lg:block;
}

.PrimarytabControl {
  @apply px-0 text-base ltr:mr-xl rtl:ml-xl;
}
.PrimarytabsListWrapper {
  @apply mb-lg border-0;
}
.PrimarytabActive {
  @apply border-dark text-dark !important;
}

.tabIcon {
  @apply mr-0 ltr:mr-sm rtl:ml-sm;
}

.tabIcon:not(:only-child) {
  @apply mr-0;
}
