.header-menu > ul {
  @apply relative flex whitespace-normal;
}
.header-menu > ul a {
  @apply transition-all duration-300 hover:text-secondaryHover;
}
.header-menu > ul > li > a {
  @apply transition-none hover:text-inherit hover:transition-all;
}

.header-menu > ul > li {
  @apply relative px-md ltr:first:pl-[2em] ltr:last:pr-[2em] rtl:first:pr-[2em] rtl:last:pl-[2em];
}
.header-menu > ul > li > ul {
  @apply pointer-events-none invisible absolute top-full z-10 mt-[1em] origin-top scale-y-0 rounded-md py-0 text-center text-white opacity-0  ring-1 ring-black ring-opacity-5 transition-all duration-700 ease-InOutBackSlower before:absolute before:left-0 before:top-[-1em]  before:block before:h-[1em] before:w-full  ltr:left-1/2 ltr:-translate-x-1/2 rtl:right-1/2 rtl:translate-x-1/2 lg:bg-[#2D2D2D] xl:mt-0 xl:shadow-lg xl:before:hidden;
}
.header-menu > ul > li:first-child > ul {
  @apply ltr:left-0 ltr:translate-x-0 rtl:right-0  rtl:left-auto rtl:translate-x-0 ltr:xl:left-1/2 ltr:xl:-translate-x-1/2  rtl:xl:right-1/2 rtl:xl:translate-x-1/2;
}

.header-menu > ul > li:last-child > ul {
  @apply ltr:right-0 ltr:left-auto ltr:translate-x-0  rtl:left-0 rtl:right-auto rtl:translate-x-0  ltr:xl:left-1/2 ltr:xl:right-auto ltr:xl:-translate-x-1/2  rtl:xl:right-1/2 rtl:xl:left-auto rtl:xl:translate-x-1/2;
}
.header-menu > ul > li:hover > ul {
  @apply pointer-events-auto visible scale-y-100 opacity-100 before:h-[-.75em] lg:py-sm;
}
.header-menu > ul > li > span,
.header-menu > ul > li > a {
  @apply flex  h-[2.8888em] items-center xl:h-[4.8888em];
}
.header-menu > ul > li span,
.header-menu > ul > li a {
  @apply whitespace-nowrap;
}
.header-menu > ul > li > span {
  @apply cursor-default;
}
.header-menu > ul > li > ul > li {
  @apply overflow-hidden;
}
.header-menu > ul ul li {
  @apply block xl:bg-transparent;
}
.header-menu > ul ul:not(.columnMenu) li > span,
.header-menu > ul ul a {
  @apply w-full justify-center py-[0.3rem] px-md text-center text-white transition-none hover:bg-[#4F4F4F] hover:text-white;
}
.header-menu > ul ul a > div {
  @apply flex h-[1.5rem] items-center;
}
.header-language-selector a {
  @apply text-dark;
}

.header-menu {
  @apply invisible absolute top-0 z-10 rounded-md bg-[#2D2D2D] opacity-0 transition-all   duration-500 ease-InOutBack ltr:left-0 rtl:right-0 xl:visible  xl:static xl:m-0 xl:bg-transparent xl:opacity-100 xl:transition-none;
}
.header-menu-wrapper.open .header-menu {
  @apply visible top-[calc(100%+12px)] opacity-100;
}
.header-menu-wrapper {
  @apply text-inherit;
}
.header-menu-wrapper.open {
  @apply text-white xl:text-inherit;
}

.hamburger {
  @apply relative -top-[1px] mx-sm flex h-[1em] w-[1em] transform-none cursor-pointer overflow-hidden transition-transform duration-200 ease-in-out;
}
.header-menu-wrapper.open .hamburger {
  @apply top-[1px] -rotate-90 delay-500;
}
.hamburger span {
  @apply absolute left-0 top-1/2 h-[1px]  w-full transform-none rounded-[4px] bg-current opacity-100;
}
.open .hamburger span {
  @appply bg-white;
}
.hamburger span:nth-child(1) {
  transition: opacity 0.2s ease-in-out 0.2s;
}
.hamburger span:nth-child(2) {
  top: calc(50% - 4px);
  width: 50%;
  left: 25%;
  transition: transform 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out 0.2s;
}
.hamburger span:nth-child(3) {
  top: calc(50% + 4px);
  width: 50%;
  left: 25%;
  transition: transform 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out 0.2s, width 0.2s ease-in-out;
}
.header-menu-wrapper.open .hamburger span:nth-child(1) {
  opacity: 0;
  transition: transform 0.2s ease-in-out 0.4s, opacity 0.2s ease-in-out 0.2s;
}
.header-menu-wrapper.open .hamburger span:nth-child(2) {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.2s ease-in-out 0.4s, top 0.2s ease-in-out 0.2s, left 0.2s ease-in-out;
  width: 100%;
  left: 0;
}
.header-menu-wrapper.open .hamburger span:nth-child(3) {
  top: 50%;
  width: 100%;
  left: 0;
  transform: rotate(45deg);
  transition: left 0.2s ease-in-out, transform 0.2s ease-in-out 0.4s, top 0.2s ease-in-out 0.2s, width 0.2s ease-in-out;
}
.menu-toggle {
  @apply relative;
}
.menu-toggle .toggle-bg {
  @apply invisible absolute top-0  left-0 h-0 w-full rounded-t-md bg-[#2D2D2D] opacity-0 transition-all duration-500 ease-InOutBack;
}
.menu-toggle div {
  @apply relative z-10;
}
.header-menu-wrapper.open .menu-toggle .toggle-bg {
  @apply visible h-[calc(100%+1em)] opacity-100;
}
/* .menu-toggle .toggle-bg:after{
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: 0;
    height: 0.75rem;
    width: 0.375rem;
    border-top-left-radius: 0.375rem;
    box-shadow: 0 -0.375rem 0 0 #2D2D2D;
    right: 100%;
    top:100%;
} */

.header-menu .columnMenu {
  @apply flex ltr:left-0 ltr:translate-x-0 ltr:xl:left-1/2 ltr:xl:-translate-x-1/2;
}
.header-menu .columnMenu {
  @apply flex rtl:left-auto rtl:right-0 rtl:translate-x-0 rtl:xl:right-1/2 rtl:xl:translate-x-1/2;
}
.header-menu .columnMenu > li {
  @apply overflow-visible rounded-none border-darkGrey last:border-none ltr:border-r ltr:text-left rtl:border-l rtl:text-right;
}
.header-menu .columnMenu a {
  @apply justify-start  text-left;
}
.columnMenu > li > a,
.columnMenu > li > span {
  @apply relative block px-md  py-xs;
}
.columnMenu > li > span {
  @apply bg-[#2d2d2d]  ltr:-mr-[2px] rtl:-ml-[2px];
}
.columnMenu > li:last-child > span {
  @apply m-0;
}
.columnMenu > li > span {
  @apply font-bold;
}
.columnMenu > li > * {
  @apply relative;
}
.columnMenu > li > *:first-child > *:after {
  @apply absolute right-[-2px] top-0 z-10 block h-full w-[4px] bg-[#2D2D2D];
  content: "";
}
.columnMenu > li > *:last-child > *:after {
  @apply hidden;
}
