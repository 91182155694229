.appsDownloadButtons {
    box-shadow: none;
}

.appsDownloadButtons:hover {
    box-shadow: 0 5px 5px -3px rgb(255 255 255 / 20%), 0 8px 10px 1px rgb(255 255 255 / 14%), 0 3px 14px 2px rgb(255 255 255 / 12%)
}

.appsDownloadButtons:active {
    box-shadow: 0 3px 1px -2px rgb(255 255 255 / 20%), 0 2px 2px 0 rgb(255 255 255 / 14%), 0 1px 5px 0 rgb(255 255 255 / 12%)
}