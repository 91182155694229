.itemOpened {
    @apply text-secondary
}

.accordionLabel p {
    @apply text-dark font-[400] text-lg hover:text-secondary transition-all duration-300 ease-in-out rtl:text-right
}

.itemOpened .accordionLabel p {
    @apply text-secondary;
}

.itemOpened .accordionIcon {
    @apply text-secondary transition-all duration-300 ease-in-out rtl:text-right
}

.accordionIcon {
    @apply rtl:ml-0 rtl:mr-[20px]
}

.tabInner {
    @apply justify-start;
}

.accordionControl:hover {
    background-color: transparent;
}

.accordionItem {
    background-color: #f6f6f6;
    border-radius: 10px;
    border: 0;
    margin-bottom: 1.111rem;
}

.accordionItem {
    @apply px-[1rem] lg:px-[2rem]
}

.boxStyles ul li {
    @apply flex items-start text-grey py-xs first:pt-0;
}
.boxStyles ul li::before {
    content: url("data:image/svg+xml,%3Csvg stroke='%230995dd' fill='%230995dd' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.47 250.9C88.82 328.1 158 397.6 224.5 485.5c72.3-143.8 146.3-288.1 268.4-444.37L460 26.06C356.9 135.4 276.8 238.9 207.2 361.9c-48.4-43.6-126.62-105.3-174.38-137z'%3E%3C/path%3E%3C/svg%3E");
    display: block;
}
.boxStyles ul li::before {
    @apply text-secondary fill-secondary ltr:mr-[1em] rtl:ml-[1em] mt-[0.3em] text-[0.9em]
}

.title p {
    @apply text-xl text-dark
}