.wrapper {
    @apply lg:mb-xl
}

.tabsList {
    @apply justify-between flex-col lg:flex-row
}

.tabControl {
    @apply w-full max-w-full lg:max-w-[32%] my-sm lg:my-0 py-[4rem] md:py-3xl lg:py-[7.666rem] px-lg rounded-lg bg-[#f8f8f8] lg:bg-transparent hover:bg-[#f8f8f8]
}

.tabActive.tabControl {
    @apply bg-[#f8f8f8]
}

.content strong {
    @apply text-secondary font-normal
}

.content ul {
    @apply list-disc text-[#737373] ltr:ml-md rtl:mr-md
} 