.disclaimer p {
  @apply text-sm;
}

.tabLists {
  @apply w-full;
}

.tabLabel {
  @apply h-auto font-[500];
}

.tabControl:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1rem;
  width: 1px;
  background-color: grey;
  opacity: 0.3;
  content: "";
  @apply ltr:left-0 rtl:right-0;
}
.tabControl {
  @apply relative flex h-auto grow items-center justify-center bg-lightBorderGrey px-sm py-md text-center capitalize first:before:hidden ltr:first:rounded-l-md ltr:last:rounded-r-md rtl:first:rounded-r-md rtl:last:rounded-l-md;
}
.tabActive {
  @apply mx-sm rounded-md bg-secondary text-white before:hidden ltr:first:ml-0 ltr:last:mr-0 rtl:first:mr-0 rtl:last:ml-0;
}
.tabActive + .tabControl:before {
  @apply hidden;
}

.tabsListWrapper {
  @apply hidden;
}

.listHeader {
  @apply mb-0 flex flex-1 flex-col items-center justify-center py-md text-center capitalize leading-[1.11rem] text-[#4a4a4a];
}
.instrumentStyles {
  @apply mb-0 flex flex-1 flex-col items-center justify-center py-sm text-center capitalize leading-[1.11rem] text-black;
}

.selectInput {
  @apply relative z-10 cursor-pointer border-0 border-transparent bg-transparent text-base font-[500] uppercase text-secondary     focus:border-transparent focus:ring-0 ltr:pl-0 rtl:pr-0 rtl:text-right;
}

.selectInput::placeholder {
  @apply text-secondary opacity-100;
}
.selectRoot {
  @apply flex items-center rounded-lg border border-secondary;
}

.mobileSelectRoot {
  @apply mb-lg items-center  rounded-lg border border-secondary  py-sm px-md;
}

.selectLabel {
  @apply m-0 cursor-pointer whitespace-nowrap  p-md;
}
.itemSelect {
  @apply rtl:text-right;
}

.selectDropdown {
  @apply w-full;
}
.rightSection {
  @apply ltr:right-4 rtl:right-auto rtl:left-4;
}

.listHeaderMobile {
  @apply mb-0 flex h-[4.44rem] w-[30vw] flex-1 flex-col items-center justify-center border-b border-b-[#efe8e8] py-[0.555rem] px-[0.277rem] text-center text-xs capitalize text-[#4a4a4a];
}

.instrumentStylesMobile {
  @apply mb-0 flex h-[4.44rem] w-[30vw] flex-1 flex-col items-center justify-center border-b border-b-[#efe8e8] py-[0.555rem] px-[0.277rem] text-center text-xs capitalize text-[#4a4a4a];
}
.productTab {
  @apply relative w-[15em] border border-secondary bg-white p-sm text-lg ltr:first:rounded-l-lg ltr:last:rounded-r-lg  rtl:first:rounded-r-lg rtl:last:rounded-l-lg lg:py-md lg:px-lg;
}
.productTab.active {
  @apply bg-secondary text-white;
}
.productTab.active * {
  @apply text-white;
}
.productTab.active:first-child:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #0995dd;
  position: absolute;
  top: 100%;
  z-index: 1;
  margin-top: -1px;
  @apply ltr:left-[2.5em] rtl:right-[2.5em];
}
.productTab.active:last-child:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #0995dd;
  position: absolute;
  top: 100%;
  z-index: 1;
  margin-top: -1px;
  @apply ltr:right-[2.5em] rtl:left-[2.5em];
}

.platformLink a {
  @apply absolute top-0 left-0 h-full w-full;
}

.rightSectionMobile {
  @apply ltr:right-0 rtl:left-0 rtl:right-auto;
}
