.article-image > span {
  @apply overflow-hidden rounded-md align-middle;
}
main .article-text h1,
main .article-text h2,
main .article-text h3,
main .article-text h4,
main .article-text h5,
main .article-text h6 {
  @apply my-md;
}
.article-text a {
  @apply break-all text-secondary hover:text-secondaryHover;
}
main .article-text table,
main .article-text figure,
main .article-text p,
main .article-text ul,
main .article-text ol {
  @apply my-md;
}
.article-text ul li {
  @apply relative ltr:pl-[1em] rtl:pr-[1em];
  list-style-type: none !important;
}
.article-text ul li:before {
  @apply absolute top-[0.6em] h-[5px] w-[5px] rounded-full bg-current content-[""] ltr:left-0 rtl:right-0;
}
.article-text ol {
  @apply list-inside list-decimal;
}
figure.table,
.article-text table {
  @apply w-full;
}
.article-text table td {
  @apply border-[1px]  border-grey p-[0.2em] text-sm lg:p-[0.5em] lg:text-base;
}

.social-share-icon {
  @apply mx-xs my-0 overflow-hidden rounded text-3xl text-lightGrey transition-colors duration-300 hover:text-secondary md:mx-0 md:my-sm;
}
.social-share-icon > * {
  @apply block;
}

.article-text h2 {
  @apply text-[1.75rem] leading-[1.2];
}

.article-text figure {
  @apply max-w-full;
}

.article-text .rich-text > * {
  @apply max-w-full;
}

.AccordionControl {
  @apply mt-md rounded-[10px] border-0 bg-[#F1F9FD] px-md py-lg text-h4 font-[600] text-secondary transition-all duration-300 ease-in-out hover:bg-[#F1F9FD] lg:py-[2.2rem] lg:px-[2.4rem] lg:text-h3;
}

.AccordionItem:first-child .AccordionControl {
  @apply mt-0;
}
.AccordionItem h3 {
  @apply m-0 !important;
}
.AccordionControl * {
  @apply font-[600] ltr:font-gilroy rtl:text-right rtl:font-dubai;
}

.itemOpened .AccordionControl {
  @apply rounded-b-[0] transition-all duration-300 ease-in-out;
}

.AccordionItem {
  @apply border-0;
}

.AccordionLabel p {
  @apply text-lg font-bold text-dark transition-all duration-300 ease-in-out;
}

.contentInner {
  @apply rounded-b-[10px] bg-[#F1F9FD] px-md py-lg  lg:py-[2.2rem] lg:px-[2.4rem];
  @apply pt-0 !important;
}
.borderAccordionIcon {
  @apply rtl:ml-0 rtl:mr-md;
}

.itemOpened .AccordionLabel p {
  @apply transition-all duration-300 ease-in-out;
}
.shortDescription p {
  @apply mb-0 text-inherit !important;
}
.tocLink {
  @apply text-dark !important;
  word-break: unset !important;
}
.noMarginTop {
  @apply mt-0 !important;
}

.redCircle {
  @apply mt-[0.25rem] h-[0.77rem] w-[0.77rem] flex-shrink-0 rounded-full border border-primary;
}

.article-text span > img:not([aria-hidden="true"]) {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
