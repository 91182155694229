.rightText h2{
    @apply font-semibold text-[2.2rem] md:text-[3rem] 2xl:text-[4rem];
}
.rightText h2 strong{
    text-shadow: 4px 4px 0px #fff, 5px 5px 0px #000, 5px 3px 0px #000, 3px 5px 0px #000;
    font-weight: 600;
}
.formWrapper input{
    @apply w-full border border-lightGrey h-full text-sm rounded-md !important
}
@media (max-width: 767px) {
    .rightText h2 strong{
        text-shadow: 2px 2px 0px #fff, 3px 3px 0px #000, 3px 2px 0px #000, 1px 3px 0px #000;
    }
}