.content h3 {
    @apply uppercase pb-sm font-bold;
}

.content strong {
    @apply inline-block pb-sm 
}

.content p {
    @apply pt-sm inline-block border-t border-[#d0d0d0]
}

.planContent {
    @apply px-xs
}

.planContent strong {
    @apply text-dark text-xl lg:text-[1.88rem] pb-sm lg:pb-md inline-block leading-8 rtl:leading-10
}
.planContent p {
    @apply text-sm
}

.image span {
    @apply rounded-lg shadow-xl 
}