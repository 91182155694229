.title { 
   @apply w-full lg:w-[30%];
}

.title div {
    @apply items-center text-center ltr:lg:text-left rtl:lg:text-right ltr:lg:items-start rtl:lg:items-end;
}

@media only screen and (max-width: 1023px) {
  .title h2 {
    text-align: center;
  }
}