.main-slider > div{
    @apply p-0 !important;
}
.thumbnails{
    @apply absolute left-1/2 -translate-x-1/2 bottom-[1em] w-[430px] xl:w-full;
}
.thumbnails > div{
    @apply xl:transform-none xl:transition-none p-0 xl:flex xl:justify-center !important;
}
.thumbnails > div > div{
   @apply xl:w-[fit-content] xl:px-sm cursor-pointer !important;
 }
 .thumbnails > div > div > div > div > span{
     @apply align-middle
 }
