.mantine-NativeSelect-wrapper select {
    @apply text-secondary border-secondary h-full text-base focus:ring-0 cursor-pointer
}

.mantine-NativeSelect-wrapper option {
    @apply p-0
}


.selectInputOpened {
    @apply py-lg bg-[#1e90ff] text-white border-0
}

.selectInputClosed {
    @apply py-lg bg-white text-secondary border-0
}

.wrapper {
    @apply border border-secondary rounded-md overflow-hidden
}

.dropdown {
    @apply bg-white border border-secondary p-0
}

.itemSelect {
    @apply hover:bg-[#1e90ff] hover:text-white bg-transparent rounded-none
}