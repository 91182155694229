
.accordionControl {
    @apply hover:bg-transparent
} 

.title p {
    @apply text-dark ltr:text-left rtl:text-right hover:text-secondary transition-all duration-300 ease-in-out;
}

.itemOpened .title p {
    @apply text-secondary
}

.accordionItemTitle {
    @apply w-full max-w-full;
}