.mainWrapper summary::marker {
    @apply content-none !important;
}

.mainWrapper i {
    @apply inline-block transition-all duration-100 ease-in origin-center !important;
}

.mainWrapper details[open] > summary > p > i {
    @apply rotate-180;
}

.mainWrapper:nth-child(even) summary {
    @apply bg-[#ECF0ED];
}

.nestedDetails:nth-child(even) summary {
    @apply bg-[#F9F9F9] border-t border-b border-[#ECEEEB];
}
.nestedDetails:nth-child(odd) summary {
    @apply bg-transparent border-0;
}

.nestedDetailsDeep:nth-child(even) summary {
    @apply bg-transparent border-0;
}
.nestedDetailsDeep:nth-child(odd) summary {
    @apply bg-[#F9F9F9] border-t border-b border-[#ECEEEB];
}
.nestedDivDeep:nth-child(even) div > div {
    @apply bg-transparent border-0;
}
.nestedDivDeep:nth-child(odd) div > div {
    @apply bg-[#F9F9F9] border-t border-b border-[#ECEEEB];
}

.mainWrapper a {
    direction: ltr !important;
    @apply rtl:text-right;
}