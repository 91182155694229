.rightShape{
    height: 75vh;
    background: linear-gradient(135.03deg, #E9FF60 9.63%, #807E4B);
    border-radius: 1.1rem;
    margin-right: -30%;
    margin-left: -10%;
    width: 150%;
}

@media (max-width:1279px) and (min-height:768px){
    .mainImage{
        width: 65%;
        bottom: 38%
    }
}
.columnText {
    @apply -mt-sm lg:mt-0
}
.columnText p{
    @apply text-dark;
}