.colored-box {
    @apply h-full flex flex-col justify-center 
}
.colored-box.box-size-lg{
    @apply p-lg;
}
.colored-box.box-size-xl{
    @apply sm:p-xl p-lg;
}
.colored-box.white-box *,
.colored-box.red-box *,
.colored-box.blue-box *{
    @apply text-white;
}
.colored-box.white-box a,
.colored-box.red-box a,
.colored-box.white-box a{
    @apply opacity-75 hover:opacity-100;
}
