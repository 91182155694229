.strip:before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: calc((100vw - 120rem)/2 + 40.5/100*120rem);
        background-color: #f6f6f6;  
        z-index: -1;
    }

    .strip:before {
        @apply ltr:right-0 rtl:left-0
    }