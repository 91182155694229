.title p {
    @apply hidden lg:block text-dark lg:max-w-[85%] lg:mx-auto
}

.customShadow {
    box-shadow: 0px 9.9077px 18.5769px rgba(0, 0, 0, 0.1);
}

.sliderStyles {
    background: rgba(217, 217, 217, 0.09);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8.0801px;
}

.reviewText p {
    @apply text-white text-sm text-center font-light
}

.appsDownloadButtons {
    box-shadow: none;
}

.appsDownloadButtons:hover {
    box-shadow: 0 5px 5px -3px rgb(255 255 255 / 20%), 0 8px 10px 1px rgb(255 255 255 / 14%), 0 3px 14px 2px rgb(255 255 255 / 12%)
}

.appsDownloadButtons:active {
    box-shadow: 0 3px 1px -2px rgb(255 255 255 / 20%), 0 2px 2px 0 rgb(255 255 255 / 14%), 0 1px 5px 0 rgb(255 255 255 / 12%)
}