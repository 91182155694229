.pump {
    animation: 2s pulse-green;
    box-shadow: 0 0 0 0 rgb(88 201 20 / 0%);
    background-color: rgba(88,201,20,0);
}
.dump {
    animation: 2s pulse-red;
    background-color: rgba(255,72,72,0);
    box-shadow: 0 0 0 0 rgb(255 72 72 / 0%);
}

@keyframes pulse-green {
    0% {
        box-shadow: 0 0 0 0 rgba(88, 201, 20, 0.3);
        background-color:rgba(88, 201, 20, 0.3);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(88, 201, 20, 0);
        background-color:rgba(88, 201, 20, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(88, 201, 20, 0);
        background-color:rgba(88, 201, 20, 0);
    }
}

@keyframes pulse-red {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 72, 72, 0.3);
        background-color: rgba(255, 72, 72, 0.3);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 72, 72, 0);
        background-color: rgba(255, 72, 72, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 72, 72, 0);
        background-color: rgba(255, 72, 72, 0);
    }
}

.link a {
    @apply absolute top-0 left-0 w-full h-full z-10 cursor-pointer
}