.pagination {
  @apply text-grey;
}
.pagination nav {
  @apply relative flex items-center;
}
.pagination a {
  @apply relative inline-flex items-center px-2 py-2 text-grey transition-colors duration-300 ease-in-out hover:text-secondary md:px-4;
}
.pagination .pagination-center {
  @apply grow text-center;
}
.pagination a.active {
  @apply text-secondary;
}
.pagination.bordered nav {
  @apply overflow-hidden rounded-xl border-2 border-lightBorderGrey;
}
.pagination.bordered .pagination-left {
  @apply border-lightBorderGrey ltr:border-r-2 rtl:border-l-2;
}
.pagination.bordered .pagination-right {
  @apply border-lightBorderGrey ltr:border-l-2 rtl:border-r-2;
}
.pagination.bordered nav {
  @apply items-stretch;
}
.pagination.bordered .pagination-icon {
  @apply flex items-center;
}
.pagination .pagination-icon a {
  @apply h-full;
}
