.hero-list ul{
    @apply md:flex-row flex-wrap md:justify-between;
}
.hero-list ul li{
    @apply md:w-[calc(50%-1rem)];
}
.hero-list span, .hero-list p {
    @apply text-grey
}
.hero-list ul li{
    @apply border-t-borderGrey border-t mt-[-1px];
}
.hero-list ul li:last-child{
    @apply border-b-borderGrey border-b border-solid
}

.title > div{
    @apply mb-0 text-grey; 
}

.red_box_text p, .red_box_text h2, .red_box_text h3, .red_box_text h4, .red_box_text h5 {
    @apply text-white;
}