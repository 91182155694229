.description p {
    @apply text-white;
}

.imageWrapper span {
    @apply rounded-md cursor-pointer;
}

.imageWrapperModal span {
    @apply rounded-3xl cursor-pointer;
}

.imageWrapperModal {
    @apply h-full flex justify-center items-center;
}