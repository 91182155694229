.tabLabel {
    @apply text-xl transition-all duration-200 ease-in-out
}

.tabActive .tabLabel {
    @apply text-2xl text-white
}

.tabControl {
    @apply lg:h-[74px] rounded-[10px_10px_0_0] -translate-y-full
}

.tabActive  {
    @apply bg-[#1C1E20] !important
}

.selectInput {
    @apply bg-transparent border-none p-0 text-dark lg:text-white capitalize rtl:text-right
}

.selectInput::placeholder {
    @apply text-dark lg:text-white capitalize !important
}

.selectRightSection {
    @apply text-dark lg:text-white rtl:left-0 rtl:right-auto
}

.selectSelectedItem {
    @apply bg-transparent text-primary
}

.selectItem {
    @apply hover:bg-transparent hover:text-primaryHover text-center capitalize text-base p-0 pb-xs px-sm last:pb-0
}

.selectDropdown {
    @apply rounded-lg
}

.selectRoot {
    @apply w-[8rem] lg:w-[9rem]
}

/* mobile stylings  */

.mobileTitle > div {
    @apply mb-md text-center items-start justify-center !important;
}

.mobileTitle h2 {
    @apply w-full;
}

.mobileTitle p {
    @apply hidden
}

.AccordionControl {
    @apply bg-transparent border-0 px-0 pt-0 pb-lg hover:bg-transparent
}

.AccordionControl:hover p {
    @apply hover:font-black
}

.AccordionItem:first-child .AccordionControl {
    @apply mt-0
}

.itemOpened .AccordionControl {
    @apply rounded-b-[0]
}

.AccordionItem {
    @apply border-0
}

.AccordionLabel {
    @apply  text-white text-base transition-all duration-200 ease-in rtl:text-right
}

.contentInner {
    @apply px-0 pt-0 pb-md
}

.AccordionContent {
    @apply px-0 text-sm
}

.borderAccordionIcon {
    @apply text-white
}

.itemOpened .AccordionLabel {
    @apply font-semibold text-lg 
}

.blue_box_text p {
    @apply text-[#111114] lg:text-white text-center text-h3 leading-[1.25] lg:ltr:text-left lg:rtl:text-right
}