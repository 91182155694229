.selectLabel {
  @apply text-xs text-secondary;
}

.wrapper {
  @apply text-secondary ltr:pr-[2rem] rtl:pl-[2rem] rtl:text-right;
  width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectInput {
  @apply cursor-pointer border-0 border-transparent bg-transparent pl-0 text-base text-secondary focus:border-transparent focus:ring-0 ltr:pr-[2rem] rtl:pl-[2rem] rtl:text-right;
  width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectInput input:selection {
  @apply bg-primary text-white;
}
.rightSection {
  @apply ltr:right-0 rtl:right-auto rtl:left-0;
}
.itemSelect {
  @apply rtl:text-right;
}
