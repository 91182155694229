.subtitle p {
  @apply text-center text-[#999] lg:text-xl lg:text-white;
}

.descriptionShort {
  @apply text-white;
}

.descriptionShort p {
  @apply text-sm text-white ltr:font-gilroy rtl:font-dubai lg:text-base lg:font-bold;
}

.descriptionLongActive p {
  @apply text-white;
}

.descriptionLongActive {
  @apply text-center text-2xl font-bold leading-[1.1] text-white ltr:font-gilroy rtl:font-dubai lg:text-3xl;
}

.descriptionLongInactive {
  @apply text-center text-xl font-bold leading-[1.1] text-[#B4B4B4] ltr:font-gilroy rtl:font-dubai lg:text-3xl;
}

.descriptionLongInactive p {
  @apply text-center text-xl font-bold leading-[1.1] text-[#B4B4B4] ltr:font-gilroy rtl:font-dubai lg:text-2xl;
}

.inactiveShadow {
  box-shadow: 0px 5px 8px #dfaaff;
}

.buttonText button:hover p {
  @apply text-white transition-all duration-300 ease-in-out;
}

@media (max-width: 1023px) {
  .mobileContainer {
    @apply container;
  }
}
@media (max-width: 1024px) {
  .shadowContainer:before {
    content: "";
    width: 5%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    z-index: 2;
    box-shadow: 10px 0 20px 0px rgb(0 0 0 / 100%);
  }

  .shadowContainer:before {
    @apply ltr:left-[-5%] rtl:right-[-5%];
  }

  .shadowContainer:after {
    content: "";
    width: 5%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    z-index: 2;
    box-shadow: -10px 0 20px 0px rgb(0 0 0 / 100%);
  }

  .shadowContainer:after {
    @apply ltr:right-[-5%] rtl:left-[-5%];
  }
}
