.title h4 {
    @apply font-body md:leading-[32px] pb-lg md:pb-[0.65rem]
}

.buttons, .buttons button {
    @apply w-full md:max-w-[calc(100%-0.85rem)]
}

.buttons button {
    @apply px-[0.5em] md:px-[1.7em]
}

.buttons:last-child button {
    @apply md:ltr:ml-auto md:rtl:mr-auto mt-sm md:mt-0
}

.firstChild a:first-child p {
    @apply ml-0
}