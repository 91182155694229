
.tabsList {
    @apply mt-lg pb-[5.555rem];
}

.tabControl {
    @apply relative w-full transition-all duration-300 ease-in-out px-lg py-xs
}

.tabControl {
    border-left: 1px solid rgba(5,6,11,.1);
}

.tabControl:last-child {
    border-right: 1px solid rgba(5,6,11,.1);
}


.tabActive {
    @apply text-secondary
}

.tabLabel {
    @apply w-full;
}

.tabLabel p  {
    @apply text-dark text-center text-lg w-full
}

.tabActive .tabLabel p {
    @apply  text-secondary transition-all duration-300 ease-in-out
}


.mobileButton button {
    @apply px-[1.5rem] mx-auto mb-lg;
}

.itemOpened {
    @apply text-secondary
}

.accordionLabel p {
    @apply text-dark text-center text-base
}

.itemOpened .accordionLabel p {
    @apply text-secondary;
}

.tabInner {
    @apply justify-start;
}

.accordionControl:hover {
    background-color: transparent;
}