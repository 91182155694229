.bgColor {
    background: linear-gradient(0deg,#eaeaea 0,#f5f5f5 16%)
}

.image img {
    @apply top-[100%] ltr:left-1/2 rtl:right-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 !important;
}

.whiteText,
.whiteText p {
    @apply text-white
}