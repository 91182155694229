.future-section,
.future-section p{    
    @apply text-white;
}
.image-wrapper span,
.image-wrapper img{
    @apply align-middle;
}

.future_text strong {
    @apply text-secondary
}