.textWrapper {
    @apply absolute py-0 px-[4.44rem] whitespace-nowrap top-[25%]
}

.buttonReadMore button {
    @apply text-white text-left text-sm md:text-base
}

.shortDescription p {
    @apply text-sm ltr:text-left rtl:text-right !important
}