.buttonWrapper button {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(9 149 221 / var(--tw-bg-opacity));
  padding: 0.7em 1.7em;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  @apply mt-xl font-body hover:bg-secondaryHover;
}
.inputWrapper {
  @apply mx-auto flex w-[35rem] max-w-full justify-center;
}
.inputWrapper input {
  @apply w-full border-0 border-b border-grey bg-transparent text-center outline-none;
}
