.line{
    border-color: linear-gradient(0deg,#fff 0,#737373 25%,#737373 75%,#fff 100%);
}
.line:before{
    content: "";
    right: 0;
    bottom: 0;
    height: 100%;
    width: 1px;
    background: #fff;
    background: linear-gradient(0deg,#fff 0,#737373 25%,#737373 75%,#fff 100%);
    position: absolute;
    opacity: .25;
}
.city-segment{
    position: absolute;
    left:0;
    height: 15%;
    display: flex;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    width:calc(100%/var(--hour-number)*8);  /* 8 if is 8 hours ---- equals 50px on desktop */
    left:calc(9*100%/var(--hour-number));   /* 9 if it starts at 9:00 */
    z-index: 1;
    justify-content: center;
    font-size: 14px;
}
.graph-container:before {
    content: "";
    width: 5%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: inset 10px 0 10px -4px rgb(255 255 255 / 80%);
}
.graph-container:after {
    content: "";
    width: 5%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    z-index: 1;
    box-shadow: inset -10px 0 10px -4px rgb(255 255 255 / 80%);
}

