.hoverCard {
    box-shadow: 0 10px 52px rgb(0 0 0 / 29%);
    @apply transition-all duration-300 ease-in-out
}

.notHoverCard {
    box-shadow: none;
    transform: scale(1);
    @apply transition-all duration-300 ease-in-out
}

.hoverScale {
    transform: scale(1.20) !important;
    @apply transition-all duration-300 ease-in-out
}

.unHoverScale {
    transform: scale(1);
    @apply transition-all duration-300 ease-in-out
}

.forceScale {
    transform: scale(1.01) !important;
}

.card-box-animation {
    animation-name: card-box-1;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 1s;
}

@keyframes card-box-1 {
    0% {
        transform: scale(1)
    }

    15% {
        transform: scale(1.10)
    }

    30% {
        transform: scale(1)
    }
}