.mobile-thumbanils {
  @apply overflow-visible !important;
}
.mobile-main-slider > div {
  @apply h-full w-full pb-0 !important;
}

.thumbnail-slide h1,
.thumbnail-slide h2,
.thumbnail-slide h3,
.thumbnail-slide h4,
.thumbnail-slide h5,
.thumbnail-slide h6 {
  @apply m-0 leading-[1.2] text-inherit ltr:font-gilroy rtl:font-dubai;
}

.thumbnail-slide p,
.thumbnail-slide ul {
  @apply text-inherit;
}

.thumbnail-slide h2 {
  @apply text-[4.77em] uppercase leading-[1.1] ltr:font-black rtl:font-bold;
}
.thumbnail-slide h3 {
  @apply text-[2.55em] uppercase  ltr:font-black rtl:font-bold;
}
.thumbnail-slide h4 {
  @apply text-[1.77em]  font-bold uppercase;
}
.thumbnail-slide h5 {
  @apply text-[1.16em]  font-bold;
}
.thumbnail-slide h6 {
  @apply text-[1.16em] font-normal leading-[1.5]  text-inherit;
}

.mobile-slide h1,
.mobile-slide h2,
.mobile-slide h3,
.mobile-slide h4,
.mobile-slide h5,
.mobile-slide h6 {
  @apply m-0 leading-[1.2] text-inherit ltr:font-gilroy rtl:font-dubai;
}

.mobile-slide p,
.mobile-slide ul {
  @apply text-inherit;
}

.mobile-slide h2 {
  @apply text-[4.77em] uppercase leading-[1.1] ltr:font-black rtl:font-bold;
}
.mobile-slide h3 {
  @apply text-[2.55em] uppercase  ltr:font-black rtl:font-bold;
}
.mobile-slide h4 {
  @apply text-[1.77em]  font-bold uppercase;
}
.mobile-slide h5 {
  @apply text-[1.16em]  font-bold;
}
.mobile-slide h6 {
  @apply text-[1.16em] font-normal leading-[1.5]  text-inherit;
}
.mobile-slide button {
  @apply mt-[2em];
}
