.faq-card{
    @apply shadow-lg transition-shadow duration-300 hover:shadow-md;
}

.faq-card img,
.faq-card p{
    @apply transition-all duration-300 hover:shadow-md
}
.faq-card img{
    filter: invert(48%) sepia(0%) saturate(1%) hue-rotate(188deg) brightness(93%) contrast(89%);
}
.faq-card:hover p{
    @apply text-secondaryHover;
}
.faq-card:hover img{
    filter: invert(35%) sepia(98%) saturate(652%) hue-rotate(162deg) brightness(87%) contrast(99%);
}