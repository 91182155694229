.lessons-presentation {
  @apply relative cursor-pointer border-b border-b-[#cdcdcd] py-xs pr-lg transition-all duration-500 ease-in-out ltr:left-0 rtl:right-0;
}

.lessons-presentation-hidden {
  @apply ltr:left-[calc(-100%-2.5rem)] rtl:right-[calc(-100%-2.5rem)];
}

.lessons-wrapper {
  @apply absolute top-0 bottom-0 z-10 h-full max-h-full w-full overflow-hidden bg-white p-md transition-all duration-500 ease-in-out ltr:right-[calc(-100%-2rem)] rtl:left-[calc(-100%-2rem)];
}

.lessons-wrapper-shown {
  @apply ltr:right-0 rtl:left-0;
}

.lessons-arrow-right {
  @apply absolute top-1/2 ml-[1em] inline-block h-0 w-0 -translate-y-1/2 transform items-center border-t-[0.28em] border-b-[0.28em] border-l-[0.35em] border-t-transparent  border-b-transparent border-l-current text-left align-middle text-secondary ltr:right-[1rem] rtl:left-0 rtl:rotate-180;
}

.lessons-arrow-left {
  @apply absolute top-1/2 inline-block h-0 w-0 -translate-y-1/2 rotate-180 transform items-center border-t-[0.28em] border-b-[0.28em] border-l-[0.35em] border-t-transparent border-b-transparent border-l-current text-left align-middle text-secondary ltr:left-0 ltr:mr-[1em] rtl:right-0 rtl:ml-[1em] rtl:rotate-0;
}
