.title p {
    @apply text-lg md:text-h3 max-w-[80%] mx-auto leading-snug 
}

.title div > div {
    @apply mt-0
}

.content strong {
    @apply text-secondary
}

.content p {
    @apply text-sm text-center ltr:md:text-left rtl:md:text-right
}