.subitle p {
  @apply text-3xl text-dark ltr:font-gilroy rtl:font-dubai;
}

.card {
  box-shadow: -3px 8px 21px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: #ffffff;
}

.card_title p {
  @apply text-secondary;
}

.card_text p {
  @apply pt-lg  text-xs;
}

.input {
  @apply h-[2rem] w-full border-0 bg-transparent py-md pl-lg pr-md text-base text-[#4a4a4a] placeholder:opacity-30 focus:border-0 focus:shadow-none focus:outline-0 focus:ring-0;
}

.content a {
  @apply text-grey last:opacity-50 hover:text-secondaryHover last:hover:opacity-100;
}

.grid {
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

@media (max-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: 1.5fr repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

.instrumentDescription p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.instrumentDescription p {
  @apply w-[5rem] md:w-[10rem] lg:w-[15rem];
}
