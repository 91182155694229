.webtrader-header h2 {
  @apply pt-sm text-[2.222rem] leading-[1] ltr:font-gilroy ltr:font-black rtl:font-dubai  rtl:font-bold 2xl:text-[2.777rem] 3xl:text-[3.333rem];
}
.webtrader-list ul {
  @apply flex-wrap md:flex-row md:justify-between;
}
.webtrader-list ul li {
  @apply md:w-[calc(50%-1rem)];
}
.webtrader-list ul li {
  @apply mt-[-1px] border-t border-t-borderGrey;
}
.webtrader-list ul li:last-child {
  @apply border-b border-solid border-b-borderGrey;
}
