.title h2, .title h1 {
    @apply text-center md:ltr:text-left md:rtl:text-right w-full
}

.AccordionControl {
    @apply hover:bg-transparent ltr:pl-0 rtl:pr-0 py-0 pb-xs
}

.AccordionLabel {
    @apply text-lg md:text-2xl
}

.borderAccordionIcon {
    @apply m-0  ltr:mr-sm md:ltr:mr-lg rtl:ml-sm md:rtl:ml-lg
}

.contentInner {
    @apply ltr:pl-[2.35rem] md:ltr:pl-[3.15rem] rtl:pr-[2.35rem] md:rtl:pr-[3.15rem] text-primary py-0
}

.contentInner p {
    @apply text-[#4F4F4F] pt-xs pb-lg
}

.AccordionContent {
    @apply px-0
}

.AccordionItem {
    @apply border-0
}


.itemOpened .AccordionIcon {
    @apply rotate-90 leading-[1] !important
}