.single-slide h1,
.single-slide h2,
.single-slide h3,
.single-slide h4,
.single-slide h5,
.single-slide h6 {
  @apply m-0 leading-[1.2] text-inherit ltr:font-gilroy rtl:font-dubai;
}

.single-slide p,
.single-slide ul {
  @apply text-inherit;
}

.single-slide h2 {
  @apply text-[4.77em] uppercase leading-[1.1] ltr:font-black rtl:font-bold;
}
.single-slide h3 {
  @apply text-[2.55em] uppercase  ltr:font-black rtl:font-bold;
}
.single-slide h4 {
  @apply text-[1.77em]  font-bold uppercase;
}
.single-slide h5 {
  @apply text-[1.16em]  font-bold;
}
.single-slide h6 {
  @apply text-[1.16em] font-normal leading-[1.5]  text-inherit;
}
.single-slide button {
  @apply mt-[2em];
}

@media (max-height: 768px) {
  .single-slide {
    @apply h-[65vh] text-[14px];
  }
  .single-slide button {
    @apply mt-[1em];
  }
}
