.content h3 {
  @apply uppercase;
}

.content strong {
  @apply inline-block pb-sm;
}

.content p {
  @apply inline-block border-t border-[#d0d0d0] pt-sm;
}

.howToCard {
  box-shadow: 14px 19px 51px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  background-color: #ffffff;
}

.strip:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: calc((100vw - 120rem) / 2 + 40.5 / 100 * 120rem);
  background-color: #f6f6f6;
  z-index: -1;
}

.strip:before {
  @apply ltr:left-0 rtl:right-0;
}

@media only screen and (max-width: 600px) {
  .strip:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: calc((100vw - 120rem) / 2 + 47.5 / 100 * 120rem);
    background-color: #f6f6f6;
    z-index: -1;
  }
  .strip:before {
    @apply ltr:left-0 rtl:right-0;
  }
}

.mobileButton button {
  @apply px-[1.5rem];
}

.heroContent p {
  @apply text-lg text-dark ltr:font-gilroy rtl:font-dubai;
}
