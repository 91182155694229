.strip:before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: calc((100vw - 120rem)/2 + 65/100*120rem);
        background-color: #f6f6f6;  
        z-index: -1;
    }

.strip:before {
    @apply ltr:left-0 rtl:right-0
    }

@media only screen and (max-width: 600px) {
    .strip:before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: calc((100vw - 120rem)/2 + 47.5/100*120rem);
        background-color: #f6f6f6;  
        z-index: -1;
    }
    .strip:before {
    @apply ltr:left-0 rtl:right-0
    }
}

.content h3 {
    @apply uppercase font-bold pb-sm;
}

.content p {
    @apply pt-sm inline-block border-t border-[#d0d0d0]
}

.guideContent p{
    @apply text-xs md:text-sm
}

.incomeContent p {
    @apply text-dark uppercase font-bold text-base lg:text-[1.33rem] whitespace-nowrap
}


.sliderBar {
    @apply bg-dark rtl:right-[calc(0%-8px)]
}

.sliderThumb {
    @apply h-[2.61111rem] w-[2.61111rem] border-0 bg-secondary  rtl:translate-x-1/2 rtl:-translate-y-1/2
}
.sliderThumb {
    box-shadow: 5px 7px 13px rgba(0, 0, 0, 0.24);
}

.sliderMarkWrapper {
    @apply top-auto bottom-[8px]
}

.sliderMark {
    @apply h-[50px] w-0 border border-[#b2b2b2] border-dashed
}

.sliderTrack:before {
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
}

.sliderTrack {
    @apply last:flex last:justify-between
}