.title div {
    @apply mt-[0.25rem];
}

.backgroundImg {
  -webkit-animation: slide-out-fwd-tr 8s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite alternate;
  animation: slide-out-fwd-tr 8s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite alternate;
}

@keyframes slide-out-fwd-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0) scale(1, 1);
    transform: translateZ(0) translateY(0) translateX(0) scale(1, 1);
    opacity: 0.9;
  }
  50% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0) scale(1, 1);
    transform: translateZ(0) translateY(0) translateX(0) scale(1, 1);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(200px) scale(1.5, 1.5);
    transform: translateZ(600px) translateY(-300px) translateX(200px) scale(1.5, 1.5);
    opacity: 0;
  }
}
