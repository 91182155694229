.mobile-language-drawer {
  @apply absolute left-full  top-0 z-10 h-full w-full overflow-y-auto bg-white py-[3.8888em]  px-md transition-all duration-500 ease-in-out;
}
.mobile-language-drawer.open {
  @apply left-0 shadow-[0_5px_10px_1px_rgba(0,0,0,0.05)];
}

.mobile-language-drawer h5 {
  @apply relative m-0 flex items-center justify-center border-b border-b-borderGrey py-[2em] px-lg text-center leading-[1.8em];
}
.mobile-language-drawer h5 > div {
  @apply absolute top-1/2 left-[0.6rem] -translate-y-1/2;
}
.mobile-language-drawer h5 span {
  @apply cursor-pointer text-[2.3rem] uppercase ltr:font-gilroy ltr:font-black rtl:font-dubai rtl:font-bold;
}
.chatIcon svg {
  @apply h-[1.35em] w-[1.35em];
}
.mobileMenuButtons button {
  @apply px-sm;
}
