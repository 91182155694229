.position-left {
    @apply items-start text-left
}
.position-center {
    @apply items-center text-center
}
.position-right {
    @apply items-end text-right
}
.dottedBgBorder {
    @apply border-b-2 border-dashed border-[rgba(0,0,0,0.1)] w-[100%]
}