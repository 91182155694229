.arrow{
    @apply h-[0.7em] w-[0.7em] relative inline-block align-middle;
}

.arrow-right{
    @apply after:absolute after:top-1/2 ltr:after:left-1/2 rtl:after:right-1/2 ltr:after:-translate-x-1/2 rtl:after:translate-x-1/2 after:-translate-y-1/2 after:w-0 after:h-0 after:inline-block after:align-middle after:border-t-[0.28em] after:border-t-transparent after:border-b-[0.28em] after:border-b-transparent ltr:after:border-l-[0.35em] rtl:after:border-r-[0.35em] ltr:after:border-l-current rtl:after:border-r-current
}
.arrow-left{
    @apply after:absolute after:top-1/2 ltr:after:left-1/2 rtl:after:right-1/2 ltr:after:-translate-x-1/2 rtl:after:translate-x-1/2 after:-translate-y-1/2 after:w-0 after:h-0 after:inline-block after:align-middle after:border-t-[0.28em] after:border-t-transparent after:border-b-[0.28em] after:border-b-transparent ltr:after:border-r-[0.35em] rtl:after:border-l-[0.35em] ltr:after:border-r-current rtl:after:border-l-current
}
.arrow-down{
    @apply after:absolute after:top-1/2 ltr:after:left-1/2 rtl:after:right-1/2 ltr:after:-translate-x-1/2 rtl:after:translate-x-1/2 after:-translate-y-1/2 after:w-0 after:h-0 after:inline-block after:align-middle after:border-l-[0.28em] after:border-l-transparent after:border-r-[0.28em] after:border-r-transparent after:border-t-[0.35em] after:border-t-current
}
.arrow-up{
    @apply after:absolute after:top-1/2 ltr:after:left-1/2 rtl:after:right-1/2 ltr:after:-translate-x-1/2 rtl:after:translate-x-1/2 after:-translate-y-1/2 after:w-0 after:h-0 after:inline-block after:align-middle after:border-l-[0.28em] after:border-l-transparent after:border-r-[0.28em] after:border-r-transparent after:border-b-[0.35em] after:border-b-current
}