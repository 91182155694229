.InvestHomeTools p {
  @apply text-dark;
}
.InvestHomeTools h2,
.InvestHomeTools h3,
.InvestHomeTools h4 {
  @apply pb-lg text-center ltr:lg:text-left rtl:lg:text-right;
}
.InvestHomeTools ul li {
  @apply relative before:absolute before:top-[0.6rem] before:h-[0.35em] before:w-[0.35em] before:rounded-full before:bg-dark ltr:pl-md ltr:before:left-0 rtl:pr-md rtl:before:right-0;
}
.InvestHomeTools video {
  @apply xl:w-[120%] xl:max-w-[120%];
}
