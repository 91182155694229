.AccordionControl {
  @apply border-0 bg-transparent px-0 pt-0 pb-md hover:bg-transparent;
}

.AccordionControl:hover p {
  @apply hover:font-black;
}

.AccordionItem:first-child .AccordionControl {
  @apply mt-0;
}

.itemOpened .AccordionControl {
  @apply rounded-b-[0];
}

.AccordionItem {
  @apply border-0;
}

.AccordionLabel {
  @apply text-base text-dark  hover:font-bold rtl:text-right;
}

.contentInner {
  @apply px-0 pt-0 pb-md;
}

.AccordionContent {
  @apply px-0 text-sm;
}

.borderAccordionIcon {
  @apply text-primary ltr:-ml-[7px] rtl:-mr-[7px];
}

.itemOpened .AccordionLabel {
  @apply font-bold;
}

.link a {
  @apply hover:text-primaryHover !important;
}

main .essentialTItle.essentialTItle {
  @apply font-body;
}
