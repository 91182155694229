.product-tabs a,
.product-types-inner > span,
.product-tabs .active {
  @apply block  h-full border-inherit px-[0.75em] py-[0.65em] hover:text-primary ltr:first:pl-[1.5em] ltr:last:pr-[1.5em] rtl:first:pr-[1.5em] rtl:last:pl-[1.5em];
}
.product-tabs a > span,
.product-types-inner > span > span,
.product-tabs .active > span {
  @apply relative  block px-[0.25em] after:-bottom-1 after:left-0 after:block after:h-[2px] after:w-full after:bg-primary after:opacity-0;
}

.product-tabs a > span:after,
.product-tabs .active > span:after {
  box-shadow: 0px 3.19654px 7.45858px #ff2121;
}
.product-types-inner {
  @apply flex;
}
/* .product-types-inner{
    @apply xl:max-w-0 transition-[max-width] duration-700 ease-in-out flex overflow-hidden;
} */

.product-tabs:hover .product-types-inner {
  @apply xl:max-w-[20em];
}
.product-tabs:hover .active > span {
  @apply text-inherit after:opacity-0;
  text-shadow: none;
}
.product-tabs:hover .active:hover > span,
.product-tabs a:hover > span,
.product-tabs .active > span {
  @apply text-primary  after:absolute  after:opacity-100;
  text-shadow: 1px 0 0 currentColor;
}
.product-tabs a .rich-text,
.product-tabs a p {
  color: inherit;
}
