.pagination{
    @apply text-grey;
}
.pagination nav{
    @apply relative flex items-center;
}
.pagination a{
    @apply relative inline-flex items-center px-2 md:px-4 py-2 text-grey transition-colors duration-300 ease-in-out hover:text-secondary;
}
.pagination .pagination-center{
    @apply text-center grow;
}
.pagination a.active{
    @apply text-secondary;
}
.pagination.bordered nav{
    @apply border-2 border-lightBorderGrey overflow-hidden rounded-xl
}
.pagination.bordered .pagination-left{
    @apply ltr:border-r-2 rtl:border-l-2 border-lightBorderGrey;
}
.pagination.bordered .pagination-right{
    @apply ltr:border-l-2 rtl:border-r-2 border-lightBorderGrey;
}
.pagination.bordered nav{
    @apply items-stretch
}
.pagination.bordered .pagination-icon{
    @apply flex items-center;
}
.pagination .pagination-icon a{
    @apply h-full;
}