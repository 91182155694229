.AccordionControl {
    @apply bg-[#F1F1F1] rounded-[10px] mt-md border-0 hover:bg-[#F1F1F1] transition-all duration-300 ease-in-out
}

.AccordionItem:first-child .AccordionControl {
    @apply mt-0
}

.itemOpened .AccordionControl {
    @apply rounded-b-[0] transition-all duration-300 ease-in-out
}

.AccordionItem {
    @apply border-0
}

.AccordionLabel p {
    @apply font-bold text-dark text-lg transition-all duration-300 ease-in-out
}

.contentInner {
    @apply bg-[#F1F1F1] rounded-b-[10px]
}

.itemOpened .AccordionLabel p {
    @apply transition-all duration-300 ease-in-out
}