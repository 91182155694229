.event {
  @apply max-w-[8.333rem] text-center text-sm text-[#4a4a4a];
}

.mobileTitle {
  @apply rotate-180 text-[4rem] ltr:font-gilroy ltr:font-black rtl:font-dubai rtl:font-bold;
}

.mobileTitle {
  writing-mode: vertical-rl;
  text-shadow: -1px 0 rgba(0, 0, 0, 0.3), 0 1px rgba(0, 0, 0, 0.3), 1px 0 rgba(0, 0, 0, 0.3), 0 -1px rgba(0, 0, 0, 0.3);
}

.mobileTitleShadow {
  @apply rotate-180 text-[4rem] ltr:font-gilroy ltr:font-black rtl:font-dubai rtl:font-bold;
}

.mobileTitleShadow:lang(de), .mobileTitle:lang(de) {
  @apply text-[2rem]
}

.afterShadow:lang(de) {
  @apply w-[11%]
}

.mobileTitleShadow {
  writing-mode: vertical-rl;
  text-shadow: -8px -6px 10px rgba(0, 0, 0, 0.3);
}
.afterShadow {
  position: relative;
}
.afterShadow:after {
  content: "";
  width: 2.5rem;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  z-index: 2;
  background: rgb(252, 255, 251);
  background: linear-gradient(90deg, rgba(252, 255, 251, 1) 50%, rgba(255, 255, 255, 0) 100%);
  @apply ltr:left-full rtl:right-full rtl:rotate-180;
}
