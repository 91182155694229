.cardContent {
  @apply m-0 flex flex-col justify-between md:flex-row;
}

.cardLeft,
.cardCenter,
.cardRight {
  @apply w-full md:w-[32%];
}

.cardCenter {
  @apply my-lg flex items-center justify-center rounded-md bg-lightBorderGrey p-lg md:my-0 md:p-xs;
}

.cardRight {
  @apply flex flex-col justify-between text-grey;
}

.date {
  @apply mb-0 flex flex-col items-start text-base text-grey md:mb-lg;
}

.linkWrapper a {
  @apply absolute top-0 left-0 z-10 h-full w-full;
}
.linkUrl {
  @apply absolute top-0 left-0 z-10 h-full w-full;
}
.cardWrapper:hover .cardCenter {
  @apply shadow-inner;
}

.readMore {
    @apply pt-xs text-base font-normal text-secondary transition-all duration-300 ease-in-out;
    text-shadow: none;
    letter-spacing: 0;
}