.mostPopularBadge {
  position: absolute;
  left: 50%;
  top: 0;
  padding: 0.2em 1.7em;
  background-color: #ff4848;
  transform: translateX(-50%);
  font-size: 0.75em;
  color: #f8f8f8;
  width: auto;
  line-height: 1.3;
  white-space: nowrap;
  text-transform: capitalize;
}

.mostPopularParent {
  position: relative;
}

.emptyHeaderColumn {
  visibility: none;
  border: none;
}

.TableIcon {
  @apply h-[13px] lg:h-[20px]
}

.customButton button {
  box-shadow: -9px 5px 18px 2px rgb(0 0 0 / 10%);
}

.customButton button {
  @apply border-0 px-xs lg:px-sm
}

.hiddenDescription a {
  @apply text-secondary hover:text-secondaryHover flex justify-center
}