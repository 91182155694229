.control {
  @apply px-0 py-xs hover:bg-transparent rtl:text-right;
}
.label {
  @apply text-3xl  text-white ltr:font-gilroy rtl:font-dubai;
}
html[lang="es"] .label,
html[lang="de"] .label {
  @apply font-semibold;
}
.item {
  @apply border-none;
}
.icon {
  @apply text-white rtl:ml-0 rtl:mr-[20px];
}
.contentInner {
  @apply p-0;
}
.mobile-menu ul li a {
  @apply opacity-70 hover:opacity-100;
}
