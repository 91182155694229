.ZachKingSection p {
  @apply text-white;
}
.ZachKingSection h3 {
  @apply pb-md text-h2 font-[300] lg:pb-0 lg:text-[2.77rem];
}
.ZachKingSection h2 {
  @apply mb-0 inline-block text-h2  font-[900] uppercase text-primary lg:block lg:pt-0 lg:text-[4.11rem];
}
html[lang="ro"] .ZachKingSection h2 {
  @apply lg:text-[3rem];
}
