.list-type-bordered {
  @apply border-b border-b-borderGrey last:border-none;
}

.list-arrow-right {
  @apply items-center text-left after:relative after:ml-[1em] after:inline-block after:h-0 after:w-0 after:border-t-[0.28em] after:border-b-[0.28em] after:border-r-[0.35em] after:border-t-transparent after:border-b-transparent after:border-r-current after:align-middle after:text-secondary;
}

.list-arrow-left {
  @apply items-center before:relative before:inline-block before:h-0 before:w-0 before:border-t-[0.28em] before:border-b-[0.28em] before:border-t-transparent before:border-b-transparent before:align-middle before:text-secondary ltr:text-left ltr:before:mr-[1em] ltr:before:border-l-[0.35em] ltr:before:border-l-current rtl:text-right rtl:before:ml-[1em] rtl:before:border-r-[0.35em] rtl:before:border-r-current;
}
.listLink * {
  @apply text-secondary hover:text-secondaryHover;
}
