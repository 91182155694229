.card-inner {
    @apply relative rounded bg-lightBorderGrey py-lg px-md items-center h-full;
}

.link-wrapper a {
    @apply absolute top-0 left-0 w-full h-full z-10;
}

.description p {
    @apply md:hidden text-sm text-dark !important;
}


.left-side {
    @apply max-w-[40%] py-0 px-xs bg-white
}

.list li{
    @apply items-center text-sm pb-xs flex text-secondary mb-0
}
.list li span{
    @apply text-grey mx-[0.25rem]
}
.icon {
    @apply mr-sm text-grey text-sm inline-block align-middle h-[1.25rem]
}

.mobile-card-inner {
    @apply relative rounded bg-[#f8f8f8] py-lg px-md flex-col h-full
}

.mobile-top {
    @apply flex px-xs
}
.mobile-left-side {
    @apply h-[6.25rem] w-[6.25rem] bg-white flex justify-center items-center p-xs rounded
}
.mobile-right-side {
    @apply pl-md flex flex-col justify-between grow
}

.mobile-list li{
    @apply flex justify-between items-center pb-[0.75rem] text-secondary
}
.mobile-list li span{
    @apply text-grey font-bold mr-xs
}
.mobile-list li div{
    @apply flex grow 
}