.arrow-wrapper > div {
  @apply origin-center rotate-0 transition-transform duration-300;
}
.trigger[aria-expanded="true"] .arrow-wrapper > div {
  @apply rotate-180;
}
.language-trigger span,
.language-trigger img {
  @apply block !important;
}
.language-trigger svg path {
  @apply stroke-inherit;
}
.language-item * {
  @apply flex  text-sm ltr:font-body rtl:font-cairo lg:text-h6;
}
.menuBody {
  @apply top-full max-h-[calc(100vh-4.8888em)] w-full overflow-auto border-0 bg-[#2D2D2D] p-0 text-white lg:w-[40rem] lg:rounded-md;
}
.language-item a {
  @apply -my-xs py-xs;
}
.language-item > a > div {
  @apply mt-[0.2em] lg:mt-[0.2em];
}

.menuItem {
  @apply p-lg text-white;
  @apply bg-transparent;
}

.menuItem a {
  @apply text-white hover:text-[#ccc];
}
.menuRoot > div > div,
.menuRoot {
  @apply static lg:relative !important;
}
.menuRoot {
  @apply h-full;
}
@media (max-width: 1023px) {
  .menuRoot > div > div > div {
    @apply top-full left-0 translate-x-0 !important;
  }
}
.itemLabel {
  width: 100%;
}
.languageBottom a {
  @apply text-secondary hover:text-secondaryHover;
}
