.tableHeader p {
    background: rgba(255,255,255,.4);
    border-radius: 5px 5px 0 0;
    padding: 0.55rem 0.833rem;
    color: white;
}

.tableBody {
    background: rgba(255,255,255,.2);
    border-radius: 0 0 5px 5px;
    padding: 0.55rem 0.833rem;
}

.tableBody p {
    @apply text-white;
}