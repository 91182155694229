.title.title div {
    @apply text-center ltr:lg:text-left rtl:lg:text-right
}

.title h2 {
    @apply w-full
}

.title p {
    @apply hidden first:block lg:block max-w-[75%] mx-auto md:max-w-full
}


.slideBox {
    background: #FFFFFF;
    border: 0.5px solid #F1F1F1;
    box-shadow: 0px 20px 50px -15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

/* .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
} */

@media (min-width: 1024px) {
    .shadowContainer:before {
    content: "";
    width: 5%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    z-index: 2;
    box-shadow: inset 10px 0 10px -4px rgb(255 255 255 / 80%);
}

  .shadowContainer:before {
    @apply ltr:left-0 rtl:right-0
  }

.shadowContainer:after {
    content: "";
    width: 5%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    z-index: 2;
    box-shadow: inset -10px 0 10px -4px rgb(255 255 255 / 80%);
}

  .shadowContainer:after {
    @apply ltr:right-0 rtl:left-0
  }
}

.animationArrowLeft {
    animation: bounceLeft 1s infinite;
}
.animationArrowRight {
    animation: bounceRight 1s infinite;
}

@keyframes bounceLeft {
  0% {
    transform: translateX(-7%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  25% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  50% {
    transform: translateX(7%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  75% {
    transform: translateX(0%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  100% {
    transform: translateX(-7%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes bounceRight {
  0% {
    transform: translateX(7%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  25% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  50% {
    transform: translateX(-7%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  75% {
    transform: translateX(0%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  100% {
    transform: translateX(7%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}