.graph:not(.inview) img {
    @apply min-h-0 h-0 !important;
}

.graph img {
    @apply min-h-full h-full transition-all top-auto duration-[0.8s] opacity-100 ease-in-out !important;
}

.graphLine:not(.inview) {
    height: 0 !important;
    opacity: 0 !important;
    transition: all 0.6s ease-in-out 0.3s !important;
}
.graphText:not(.inview) {
    opacity: 0 !important;
    transform: scale(0.8, 0.8) !important;
    transition: all 0.3s ease-in-out !important;
}

.graphLine {
    @apply border-l border-grey border-dashed block absolute transition-all duration-[1.4s] ease-in-out opacity-100 ;
}

.graphText {
    @apply absolute pl-sm transition-all duration-[2s] ease-in-out scale-[1,1] opacity-100 text-xs md:text-sm;
}

.graphLine::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ff4848;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: 6px solid #fff;
    box-sizing: content-box;
    top: 0;
}

.graphLine::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ff4848;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: 6px solid #fff;
    top: auto;
    bottom: 0;
    box-sizing: content-box;
}

@media only screen and (max-width: 768px) {
    .graphLine::before, .graphLine::after {
        width: 7px;
        height: 7px;
        border-width: 3px;
    }
}


.graphLineOne {
    @apply h-[76%] top-[-15%] md:top-[15px]  md:h-[59.5%] bottom-[39.5%] left-[10.5%]
}

.graphTextOne {
    @apply left-[10.5%] top-[-15%] text-left max-w-[25%] lg:max-w-full md:top-[15px]
}
.graphLineTwo {
    @apply left-[23%] h-[31%] bottom-[38%]
}

.graphTextTwo {
    @apply left-[23%] top-[30%] text-left max-w-[16%] pl-[0.55rem] md:pl-sm
}
.graphLineThree {
    @apply h-[103.5%] top-[-15%] md:top-0 md:h-[87.5%] left-[40%] bottom-[12%]
}

.graphTextThree {
    @apply max-w-[30%] md:max-w-[25%] top-[-15%] left-[40%] text-left  md:top-0 
}
.graphLineFour {
    @apply h-[21%] right-[25.5%] bottom-[55.5%]
}

.graphTextFour {
    @apply top-[23%] right-[25.5%] max-w-[30%] md:max-w-[25%] text-right pr-[0.55rem] md:pr-[0.833rem] pl-0
}
.graphLineFive {
    @apply h-[101%] md:h-[86%] bottom-[14.5%] right-[5%]
}

.graphTextFive {
    @apply top-[-15%] md:top-0 right-[5%] max-w-[25%] md:max-w-[30%] text-right pr-[0.55rem] md:pr-[0.833rem] pl-0
}

