.card {
  @apply relative rounded-lg px-[2.5rem] py-lg shadow-lg transition-shadow;
}

.readMore {
  @apply pt-xs text-lg font-normal text-[#999] transition-all duration-300 ease-in-out;
  text-shadow: none;
  letter-spacing: 0;
}
.card:hover .readMore {
  @apply text-primary;
  text-shadow: 1px 0 0 currentColor;
  letter-spacing: 0.01em;
}
.articleDescription p {
  @apply text-[#4F4F4F];
}
.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.clamp span {
  background: none !important;
}
.imgWrapper {
  @apply rotate-0 rounded-lg shadow-none shadow-none transition-all duration-500 ease-in-out;
}
.card:hover .imgWrapper {
  @apply -rotate-6 rounded-2xl shadow-lg;
}
.imgWrapper img {
  @apply scale-100 transition-all duration-500 ease-in-out;
}

.card:hover .imgWrapper img {
  @apply scale-110;
}
.activePublisherLogo,
.publisherLogo {
  @apply flex scale-100 cursor-pointer items-center py-sm opacity-50 grayscale filter transition-all duration-500 ease-InOutBack hover:scale-125 hover:opacity-100;
}
.activePublisherLogo,
.publisherLogo:hover {
  filter: brightness(0) saturate(100%) invert(61%) sepia(98%) saturate(5154%) hue-rotate(335deg) brightness(105%)
    contrast(111%);
}
.activePublisherLogo {
  @apply scale-125 opacity-100;
}
.linkUrl {
  @apply absolute top-0 left-0 z-10 h-full w-full !important;
}
