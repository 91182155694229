.divider div > div > span {
  @apply text-[1.77rem];
}

.ipo_details_content p {
  @apply text-sm;
}

main .ipo_details_content h1,
main .ipo_details_content h2,
main .ipo_details_content h3,
main .ipo_details_content h4,
main .ipo_details_content h5,
main .ipo_details_content h6,
main .ipo_details_content table,
main .ipo_details_content figure,
main .ipo_details_content p,
main .ipo_details_content ul {
  @apply mb-md;
}

.ipo_details_content ul {
  @apply list-disc ltr:ml-md rtl:mr-md;
}

.ipo_details_content li {
  @apply text-sm text-grey;
}

@media (min-width: 1024px) {
  .twoColumns > div {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    column-gap: 7em;
    -moz-column-gap: 7em;
    -webkit-column-gap: 7em;
    display: block;
    text-align: justify;
  }
}
