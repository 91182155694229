.analystBox {
  @apply relative mb-xl min-h-[19.4rem] overflow-hidden rounded-lg bg-[#f5f5f5];
}

.leftSide {
  @apply relative w-full py-md pr-0 ltr:pl-sm rtl:pr-sm lg:w-[60%] lg:py-[3.88rem] ltr:lg:pl-[3.33rem] rtl:lg:pr-[3.33rem];
}

.line {
  margin-right: auto;
  margin-left: auto;
  height: 1px;
  background-color: #dfdfe7;
}
.media {
  margin-right: auto;
  margin-left: auto;
  padding: 2.7rem 0 6.33rem;
  position: relative;
  z-index: 1;
}

.bgText {
  position: absolute;
  top: auto;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 100%;
}

.bgText > p {
  color: rgba(204, 204, 204, 0.11);
  font-size: 7rem;
  line-height: 26.28px;
  text-transform: uppercase;
  text-align: center;
}
