.bgText {
    position: absolute;
    top: auto;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

.bgText > p {
    color: rgba(204,204,204,.11);
    line-height: 26.28px;
    text-transform: uppercase;
    text-align: center;
}

.bgText > p {
    @apply text-[3rem] md:text-[7rem]
}