.section {
    @apply bg-[#f8f8f8] py-[5rem]
}

.imageStyles {
    @apply flex-shrink-0 ltr:md:mr-lg rtl:md:ml-lg
}

.content p {
    @apply text-dark text-center ltr:md:text-left rtl:md:text-right;
}